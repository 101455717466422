<template>
	<div id="w_grid-1564456455396" class="w_grid-000" data-tmpleditable="editable">
		<div class="w_grid signal">
			<div class="e_box e_box-000 p_gridbox">
				<div id="content_box-1564456455396-0" class="e_box e_box-000 d_gridCell_0 p_gridCell ND_empty"
					data-editablecontent="1564456455396">
					<div id="w_grid-1564477596926" class="w_grid-000">
						<div class="w_grid signal">
							<div class="e_box e_box-000 p_gridbox">
								<div id="content_box-1564477596926-0" class="e_box e_box-000 d_gridCell_0 p_gridCell">
									<div id="c_portalResPublic_codeContainer-15773317105102707"
										class="c_portalResPublic_codeContainer-01001">
										<div class="e_box">
											<div class="in_about_b hxd">
												<img class="p_bgBox" src="@/assets/picture/bb3.jpg" alt="Green Stone ">
												<div class="text">
													<!-- 标题开始 -->

													<p class="bannerTitle en_font_bold">Looking
														forward to your joining next
													</p>
													<!-- 标题结束 -->

													<!-- banner概要开始 -->
													<article class="summry" style="display:none;">Looking forward to
														your joining next</article>
													<p class="banner_en en_font">Be Different,
														Be Success !</p>
													<!-- banner概要结束 -->
												</div>
												<div class="in_about_in "></div>
											</div>
										</div>
									</div>
									<div id="w_grid-1619080076487" class="w_grid-000">
										<div class="w_grid signal">
											<div class="e_box e_box-000 p_gridbox">
												<div id="content_box-1619080076487-0"
													class="e_box e_box-000 d_gridCell_0 p_gridCell">
													<div id="w_grid-1619080076500" class="w_grid-001">
														<div class="w_grid signal">
															<div class="e_box e_box-000 p_gridbox">
																<div id="content_box-1619080076500-0"
																	class="e_box e_box-000 d_gridCell_0 p_gridCell">
																	<div id="w_grid-1619080076506" class="w_grid-001">
																		<div class="w_grid ">
																			<div class="e_box e_box-000 p_gridbox">
																				<div id="content_box-1619080076506-0"
																					class="e_box e_box-000 d_gridCell_0 p_gridCell">
																					<div id="c_portalResNews_category-16190800794976812"
																						class="c_portalResNews_category-01001">
																						<div class="NavList_a1">
																							<ul class="clearfix">
																								<li
																									class="en_font_bold">
																									<a
																										title="公司新闻"
																										class="more">公司新闻</a>
																								</li>
																								<li
																									class="en_font_bold">
																									<a
																										title="行业新闻"
																										class="more">行业新闻</a>
																								</li>
																							</ul>
																						</div>

																					</div>
																				</div>
																				<div id="content_box-1619080076506-1"
																					class="e_box e_box-000 d_gridCell_1 p_gridCell">
																					<div id="c_portalResBreadcrumb_nav-16190800793581381"
																						class="c_portalResBreadcrumb_nav-01001">
																						<!--homePage  首页-->
																						<div class="e_box e_box-000 p_breadcrumbList"
																							data-ename="面包屑导航">

																							<div
																								class="e_breadcrumb e_breadcrumb-001 p_breadcrumbFirst">
																								<span class="weizhi"><i
																										class="iconfont"></i>Your
																									location：</span>
																								<a href="index.html">
																									<div class="font">
																										<span><span
																												class="i_homePage">首页</span></span>
																									</div>
																								</a>
																							</div>
																							<!--compositePage 应用综合页对象-->
																							<!--navBreadcrumb 应用数据集合对象（list集合）-->

																							<div
																								class="e_title e_GuideLabel-001 p_end">
																								<div class="font">
																									<span
																										class="i_separator">/</span>
																								</div>
																							</div>
																							<div
																								class="e_breadcrumb e_breadcrumb-001 p_breadcrumb contentBox">
																								<a>
																									<div class="font">
																										<span>新闻中心</span>
																									</div>
																								</a>
																							</div>
																							<div
																								class="e_title e_GuideLabel-001 p_end">
																								<div class="font">
																									<span
																										class="i_separator">/</span>
																								</div>
																							</div>
																							<div
																								class="e_breadcrumb e_breadcrumb-001 p_breadcrumb contentBox">
																								<a>
																									<div class="font">
																										<span>行业新闻</span>
																									</div>
																								</a>
																							</div>
																						</div>

																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div id="w_grid-1564477596966" class="w_grid-000">
					<div class="w_grid signal">
						<div class="e_box e_box-000 p_gridbox">
							<div id="content_box-1564477596966-0" class="e_box e_box-000 d_gridCell_0 p_gridCell">
								<div id="w_grid-1564477596976" class="w_grid-001">
									<div class="w_grid signal">
										<div class="e_box e_box-000 p_gridbox">
											<div id="content_box-1564477596976-0"
												class="e_box e_box-000 d_gridCell_0 p_gridCell">
												<div id="c_portalResNews_detail-15644776604697218"
													class="c_portalResNews_detail-01001">
													<div class="e_box p_NewsDetail" data-ename="资讯详情" data-infoid="39">
														<!-- 移动端焦点图 -->
														<!-- 移动端焦点图 结束 -->
														<!--这是标题区topFlag recommandFlag-->
														<div class="e_box p_topBox">
															<div class="e_box p_header">
																<!--置顶  图标-->
																<!--推荐  图标-->
																<div class="e_box p_TitleBoxA">
																	<h1 class="e_title h1 p_headA">
																		<div class="font">
																			{{detaildata.name}}
																		</div>
																	</h1>
																</div>
															</div>
															<ul class="e_box p_dataSource borderT_default"
																data-ename="资讯信息">
																<li class="sort"><span class="i_sort">分类：</span><a
																		href="index7.html">{{detaildata.articleType}}</a>
																</li>
																<li class="auth"><span class="i_auth">作者：</span>
																</li>
																<li class="source"><span class="i_source">来源：</span>
																</li>
																<!-- <li><span class="i_pubDate">发布时间：</span>2022-04-07</li> --><!--YYYY-MM-DD hh:mm:ss时间格式根据情况自定义-->
																<li class="date"><span
																		class="i_pubDate">发布时间：</span>{{detaildata.releaseTime}}
																</li>

																<li class="pvs"><span class="i_pvs">访问量：</span><span
																		class="p_viewCount">0</span>
																</li>
															</ul>
															<!--概要信息-->
															<!-- <p class="e_article p_summary background_info"
																data-ename="资讯概要信息" v-html="detaildata.content">
																
															</p> -->
															<!--概要信息结束-->
														</div>
														<!--新闻图集-->
														<div class="e_box p_imgsBox borderB_default">
															<div class="swiper-container gallery-top">
																<div class="swiper-wrapper">
																	<div data-foreachitem="" class="swiper-slide">
																		<img src="@/assets/picture/s.png"
																			lazy-src="//img202.yun300.cn/repository/image/28422683-a78e-4720-bd1a-0c884f530cd0.jpg_{i}xaf.jpg?tenantId=234007&viewType=1"
																			class='js_thumb' title="简单了解一下点火器"
																			alt="简单了解一下点火器">

																		<div
																			class="e_box p_SummaryBox background_shade">
																			<div class="e_box p_textBoxs color_horizon">
																				<h4 class="e_title p_ImgHead color_horizon"
																					data-ename="图片标题">
																					简单了解一下点火器
																				</h4>
																				<p class="e_article p_ImgSummary color_horizon"
																					data-ename="图片描述">
																					简单了解一下点火器
																				</p>
																			</div>
																		</div>
																	</div>
																</div>
																<!-- Add Arrows -->
																<div
																	class="h2 background_default swiper-button-next swiper-button-white">
																	<i class="iconfont color_horizon">&#xe6af;</i>
																</div>
																<div
																	class="h2 background_default swiper-button-prev swiper-button-white">
																	<i class="iconfont color_horizon">&#xe6b0;</i>
																</div>
																<div
																	class="swiper-pagination p_pagination color_horizon h4">
																</div>
															</div>
															<div class="swiper-container gallery-thumbs">
																<div class="swiper-wrapper">
																	<div data-foreachitem="" class="swiper-slide">
																		<div class="p_sImgBox">
																			<img src="@/assets/picture/s.png"
																				lazy-src="//img202.yun300.cn/repository/image/28422683-a78e-4720-bd1a-0c884f530cd0.jpg_{i}xaf.jpg?tenantId=234007&viewType=1"
																				class='js_thumb' title="简单了解一下点火器"
																				alt="简单了解一下点火器">
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div class="e_box p_infoBox">
															<!--这是标题区topFlag recommandFlag-->
															<div class="e_box p_header">
																<!--置顶  图标-->
																<!--推荐  图标-->
																<div class="e_box p_TitleBoxA">
																	<h1 class="e_title p_headA h3 font_thick">
																		<div class="font">
																			<i class="iconfont"></i>
																			简单了解一下点火器
																		</div>
																	</h1>
																</div>
															</div>
															<!--概要信息-->
															<p class="e_article p_summary" data-ename="资讯概要信息">
																<span><span
																		class="i_SummaryBox">【概要描述】</span></span>点火器主要分为三种：电子脉冲点火器：脉冲式由于功率小，点火能量低，火花温度低。对于一些闪点高、浓度低的混合气体，爆炸燃烧器在点燃时会自燃。发生率低，经常发生点火失败或爆燃等安全事故。长期连续点火容易烧坏燃油燃烧器。电子高频高压点火器：功率大，点火能量大，火花温度高，但点火时间一般不超过5分钟，否则容易发热烧坏，又大又重，难安装和运输。
															</p>
															<ul class="e_box p_dataSource e_box p_dataSource borderB_default"
																data-ename="资讯信息">
																<li class="sort"><span class="i_sort">分类：</span><a
																		href="index7.html">行业新闻</a>
																</li>
																<li class="auth"><span
																		class="i_auth">作者：{{detaildata.author}}</span>
																</li>
																<li class="source"><span
																		class="i_source">来源：{{detaildata.source}}</span>
																</li>
																<!-- <li><span class="i_pubDate">发布时间：</span></li> --><!--YYYY-MM-DD hh:mm:ss时间格式根据情况自定义-->
																<li class="date"><span
																		class="i_pubDate">发布时间：</span>{{detaildata.releaseTime}}
																</li>
																<li class="view"><span class="i_pvs">访问量：</span><span
																		class="p_viewCount">{{detaildata.pageView}}</span>
																</li>
															</ul>
															<!--概要信息结束-->
														</div>
														<!--正文内容-->
														<div class="e_title p_articlesTitle" data-ename="描述标题">
															<div class="font">详情 </div>
														</div>
														<div class="e_box p_articles" data-ename="资讯详细描述"
															v-html="detaildata.content">

														</div>
														<!--正文内容 结束-->
														<div class="e_box p_KeywordBox" data-ename="关键词">
															<span class="i_keywordLabel">关键词：</span>
															<p class="p_tags">
																<button data-foreachitem="" type="button"
																	class="btn btn-default">{{detaildata.keyword}}</button>
															</p>
														</div>
														<div class="e_box p_QRCode borderT_default" data-ename="打开手机查看">
															<p class="e_title p_SaveText" data-ename="二维码引导信息"><span
																	class="i_qrcode">扫二维码用手机看</span>
															</p>
															<p class="e_image js_qrcode" data-ename="二维码"></p>
														</div>
														<!--分页-->
														<div class="e_box d_pcDom p_PrevAndNext borderB_default"
															data-ename="上一篇、下一篇信息区">
															<div v-if="detaildata.upId" class="e_box p_previewBox" @click="changetoUS(detaildata.upId)">
																<span class="i_title">
																	<span class="i_pageUpTitle">上一个</span>
																	<!-- <span class="clons">:</span> -->
																</span>
																<a href="javascript:;" data-ename="上一篇信息区">
																	<span class="p_PreviewTitle">{{detaildata.upTitle}}</span>
																</a>

															</div>
															<!-- 下一篇 -->
															<div v-if="detaildata.downId" class="e_box p_nextBox" @click="changetoUS(detaildata.downId)">
																<span class="i_title">
																	<span class="i_pageDownTitle">下一个</span>
																	<!-- <span class="clons">:</span> -->
																</span>
																<a href="javascript:;" data-ename="下一篇标题">
																	<span class="p_PreviewTitle">{{detaildata.downTitle}}</span>
																</a>

															</div>
														</div>
														<!--分页end-->
														<!--分页-->
														<div class="e_box p_PrevAndNextMo p_PrevAndNext borderB_default"
															data-ename="上一篇、下一篇信息区">
															<div v-if="detaildata.upId" class="e_box p_previewBox" @click="changetoUS(detaildata.upId)">
																<span class="i_title">
																	<span class="i_pageUpTitle">上一个</span>
																	<!-- <span class="clons">:</span> -->
																</span>
																<a href="javascript:;" data-ename="上一篇信息区">
																	<span class="p_PreviewTitle">{{detaildata.upTitle}}</span>
																</a>

															</div>
															<!-- 下一篇 -->
															<div v-if="detaildata.downId" class="e_box p_nextBox" @click="changetoUS(detaildata.downId)">
																<span class="i_title">
																	<span class="i_pageDownTitle">下一个</span>
																	<!-- <span class="clons">:</span> -->
																</span>
																<a href="javascript:;" data-ename="下一篇标题">
																	<span class="p_PreviewTitle">{{detaildata.downTitle}}</span>
																</a>

															</div>
														</div>
														<!--分页end-->
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>

</template>

<script>
	import $ from 'jquery'
	import '@/assets/css/page_impt_news_details.css';
	import {
		articleDetails
	} from '@/api'
	export default {
		// props: ['id'],
		data() {
			return {
				detaildata: []
			}
		},
		watch:{
			'$route'(to, from){
				this.detail(this.$route.params.id);
			}
		},
		mounted() {
			console.log(this.$route.params.id,'this.$route.params.id')
			this.detail(this.$route.params.id);
		},
		methods: {
			changetoUS(id){
			        // if(this.activeName=='新闻资讯'){
				this.$router.push({
					path:'/newdetail/'+id
				})
			},
			detail() {
				var parm = {
					id: this.$route.params.id
				}
				articleDetails(parm).then(
					(res) => {
						if (res.status == 200) {
							this.detaildata = res.data.data[0];
						}
					}
				).catch((req) => {
					this.$alert(req.message, ' ', {
						confirmButtonText: '确定',
						callback: action => {
							this.$message({
								type: 'info',
								message: `action: ${ action }`
							});
						}
					});
				});
			},
		}
	}
</script>

<style>

</style>