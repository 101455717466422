import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import aboutus from '../views/about-us.vue'
import projuct from '../views/projuct.vue'
import news from '../views/news.vue'
import Feedback from '../views/Feedback.vue'
import contact from '../views/contact.vue'
import projectdetail from '../views/projectdetail.vue'
import newdetail from '../views/newdetail.vue'
import frProjectDetail from '../views/frProjectDetail.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: aboutus
  },
  {
    path: '/product',
    name: 'product',
    component: projuct
  },
  {
    path: '/news',
    name: 'news',
    component: news
  },
  {
    path: '/Feedback',
    name: 'Feedback',
    component: Feedback
  },
  {
    path: '/contact',
    name: 'contact',
    component: contact
  },
  {
    path: '/frProductDetail/:id',
    name: 'frProductDetail',
    component: frProjectDetail
  },
  {
    path: '/projectdetail',
    name: 'projectdetail',
    component: projectdetail
  },
  {
    path: '/newdetail',
    name: 'newdetail',
    component: newdetail
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
      }, 500); // 延迟时间应与过渡效果的时间相匹配
    });
  }
})
// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0);

//   next();
// });
// router.afterEach((to, from, next) => {
//   window.scrollTo(0, 0);

//   next();
// });
export default router
