<template>
  <div class="projectContent">
    <div class="phead_box"></div>

    <div class="project_title">{{ $t("user.projectList") }}</div>
    <div class="project_content">
      <div
        class="project_item"
        v-for="(item, index) in projectData"
        :key="index"
        @click="toPages(item.projectId)"
      >
        <img :src="item.imgUrl" alt="" />
        <p>{{ item.msg }}</p>
      </div>
    </div>

    <div class="pagespagin">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :total="projectData.length"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageSize: 20,
    };
  },
  computed: {
    projectData() {
      return [
        {
          imgUrl: require("@/assets/furuiImg/lights/1/a001.jpg"),
          msg: this.$t("user.project1"),
          projectId: "01",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/2/b2246.jpg"),
          msg: this.$t("user.project2"),
          projectId: "02",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/3/c2259.jpg"),
          msg: this.$t("user.project3"),
          projectId: "03",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/4/d2269.jpg"),
          msg: this.$t("user.project4"),
          projectId: "04",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/5/e2277.jpg"),
          msg: this.$t("user.project5"),
          projectId: "05",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/6/f2284.jpg"),
          msg: this.$t("user.project6"),
          projectId: "06",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/7/g2288.jpg"),
          msg: this.$t("user.project7"),
          projectId: "07",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/8/h2305.jpg"),
          msg: this.$t("user.project8"),
          projectId: "08",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/9/i2321.jpg"),
          msg: this.$t("user.project9"),
          projectId: "09",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/10/j2333.jpg"),
          msg: this.$t("user.project10"),
          projectId: "10",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/11/k2341.jpg"),
          msg: this.$t("user.project11"),
          projectId: "11",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/12/l2357.jpg"),
          msg: this.$t("user.project12"),
          projectId: "12",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/13/m2368.jpg"),
          msg: this.$t("user.project13"),
          projectId: "13",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/14/n2377.jpg"),
          msg: this.$t("user.project14"),
          projectId: "14",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/15/o2392.jpg"),
          msg: this.$t("user.project15"),
          projectId: "15",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/16/p2400.jpg"),
          msg: this.$t("user.project16"),
          projectId: "16",
        },
        {
          imgUrl: require("@/assets/furuiImg/lights/17/q2312.jpg"),
          msg: this.$t("user.project17"),
          projectId: "17",
        },
      ];
    },
  },
  uodated() {
    Vue.set(this.data, "inner", {
      ...this.data.inner,
      key: "new value",
    });
  },
  mounted() {},
  methods: {
    toPages(projectId) {
      this.$router.push({ path: "frProductDetail/" + projectId });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .phead_box {
    height: 270px !important;
  }
}
.projectContent {
  width: 100%;
  .phead_box {
    width: 100%;
    height: 520px;
    background: url(../assets/furuiImg/probanner.webp) no-repeat;
    background-size: cover;
    background-position: center;
  }

  .project_title {
    width: 100%;
    text-align: center;
    font-size: 30px;
    margin: 80px auto 30px;
  }

  .project_content {
    width: 80vw;
    margin: 30px auto 50px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 25%);
    .project_item {
      width: 18vw;
      margin-bottom: 20px;
      > img {
        width: 18vw;
        height: 18vw;
        border-radius: 5px;
        margin-bottom: 10px;
      }
      > p {
        width: 18vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .pagespagin {
    margin: 80px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>