<template>
  <div>
    <div class="main pagebox">
      <div id="w_grid-1561969673372" class="w_grid-000">
        <div class="w_grid signal">
          <div class="e_box e_box-000 p_gridbox">
            <div
              id="content_box-1561969673372-0"
              class="e_box e_box-000 d_gridCell_0 p_gridCell"
            >
              <div
                id="c_portalResnav_main-15834620458466587"
                class="c_portalResnav_main-01001002 allheader_common"
              >
                <el-row :gutter="10" class="allhc_menu">
                  <el-col :xs="0" :sm="24" class="menu_elcol">
                    <div class="pc_menu">
                      <div class="logo_img">
                        <img src="./assets/furuiImg/topleftLogo.png" alt="" />
                      </div>
                      <div class="pc_menu_con">
                        <div
                          @click="toPages('home')"
                          :class="{ menuActive: activeIndex === 0 }"
                        >
                          {{ $t("user.login") }}
                        </div>
                        <div
                          @click="toPages('aboutus')"
                          :class="{ menuActive: activeIndex === 1 }"
                        >
                          {{ $t("user.aboustus") }}
                        </div>
                        <div
                          @click="toPages('project')"
                          :class="{ menuActive: activeIndex === 2 }"
                        >
                          {{ $t("user.Products") }}
                        </div>
                        <div
                          @click="toPages('contactus')"
                          :class="{ menuActive: activeIndex === 3 }"
                        >
                          {{ $t("user.Contactus") }}
                        </div>
                        <div
                          class="languageBtn"
                          @mouseenter="showLangage"
                          @mouseleave="handleMouseLeave"
                        >
                          {{ $t("user.Language") }}
                          <div class="languageBox" v-show="isShowlan">
                            <div
                              class="lanBtn lanBtna"
                              @click="changeLanguage('zh')"
                            >
                              中文
                            </div>
                            <div
                              class="lanBtn lanBtnb"
                              @click="changeLanguage('en')"
                            >
                              English
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="0">
                    <div class="mobile_menu">
                      <el-button
                        @click="drawer = true"
                        type="primary"
                        custom-class="mobilemenuBtn"
                        style="margin-left: 16px"
                      >
                        <i class="el-icon-s-fold"></i>
                      </el-button>
                      <el-drawer
                        size="50%"
                        custom-class="momenuDrawer"
                        :visible.sync="drawer"
                        :append-to-body="true"
                        :direction="direction"
                        :before-close="handleClose"
                      >
                        <div
                          class="jumpBtn"
                          :class="{ momenuActive: activeIndex === 0 }"
                          @click="toPages('home')"
                        >
                          {{ $t("user.login") }}
                        </div>
                        <div class="qiege_line"></div>
                        <div
                          class="jumpBtn"
                          :class="{ momenuActive: activeIndex === 1 }"
                          @click="toPages('aboutus')"
                        >
                          {{ $t("user.aboustus") }}
                        </div>
                        <div class="qiege_line"></div>
                        <div
                          class="jumpBtn"
                          :class="{ momenuActive: activeIndex === 2 }"
                          @click="toPages('project')"
                        >
                          {{ $t("user.Products") }}
                        </div>
                        <div class="qiege_line"></div>
                        <div
                          class="jumpBtn"
                          :class="{ momenuActive: activeIndex === 3 }"
                          @click="toPages('contactus')"
                        >
                          <a href=""></a>{{ $t("user.Contactus") }}
                        </div>
                        <div class="qiege_line"></div>
                        <div
                          class="jumpBtn lanChanBtn"
                          @click="changeLanguage('zh')"
                        >
                          {{ $t("user.Chinese") }}
                        </div>
                        <div class="qiege_line"></div>
                        <div
                          class="jumpBtn lanChanBtn"
                          @click="changeLanguage('en')"
                        >
                          {{ $t("user.English") }}
                        </div>
                      </el-drawer>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <transition name="fade">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div id="w_grid-1583471753649" class="w_grid-000 foor_common">
        <div class="footerCommon">
          <div class="foot_one">
            <el-row :gutter="10" class="foot_one_er">
              <el-col :xs="1" :sm="6" v-if="!isMobile"
                ><div class="grid-content one_box">
                  <div class="circle_box">
                    <i class="el-icon-phone-outline"></i>
                  </div>
                  <span>13905942212</span>
                </div></el-col
              >
              <el-col :xs="1" :sm="6" v-if="!isMobile"
                ><div class="grid-content one_box">
                  <div class="circle_box">
                    <i class="el-icon-message"></i>
                  </div>
                  <span>watta@fullyfj.com</span>
                </div></el-col
              >
              <el-col :xs="1" :sm="10" v-if="!isMobile"
                ><div class="grid-content one_box">
                  <div class="circle_box">
                    <i class="el-icon-location-outline"></i>
                  </div>
                  <span>{{ $t("user.chongqingcontent30") }}</span>
                </div></el-col
              >
              <el-col :xs="16" :sm="2"
                ><div class="grid-content one_wechat">
                  <img src="./assets/image/20241203111436.png" alt="" />
                  <p>{{ $t("user.chongqingcontent32") }}</p>
                </div></el-col
              >
            </el-row>
          </div>
          <div class="heng_line" v-if="!isMobile"></div>
          <div class="foot_two">
            <el-row :gutter="10">
              <el-col :xs="20" :sm="12"
                ><div class="grid-content foot_copyright">
                  @CopyRight 2009-2014 版权所有 福瑞科技电子有限公司@版权声明
                  闽ICP备2024080652号 All rights reserved
                </div></el-col
              >
              <el-col :xs="4" :sm="12" v-if="!isMobile"
                ><div class="grid-content foot_menu">
                  <p @click="toPages('home')">{{ $t("user.login") }}</p>
                  <div class="su_line"></div>
                  <p @click="toPages('aboutus')">{{ $t("user.aboustus") }}</p>
                  <div class="su_line"></div>
                  <p @click="toPages('project')">{{ $t("user.Products") }}</p>
                  <div class="su_line"></div>
                  <p @click="toPages('contactus')">
                    {{ $t("user.Contactus") }}
                  </p>
                </div></el-col
              >
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import request from "@/request/index.js";
import $ from "jquery";
export default {
  data() {
    return {
      isShow: false,
      isMobile: false,
      mobileMenuShow: false,
      isShowlan: false,
      drawer: false,
      direction: "rtl",
      activeIndex: 0, // 默认第一个菜单项激活
      language: localStorage.getItem("languageSet") || "zh",
    };
  },
  created() {
    console.log("获取到的路由" + this.$route.path);
    this.restoreActiveIndex(this.$route.path);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    // jquery 代码块
    // banner图片上面的文字特效

    // 导航栏代码
    // $(".btn_nav").click(function () {
    //   console.log("触发了点击");
    //   $(".pc_nav").toggleClass("cur");
    // });

    $(window).scroll(function () {
      var sTop = $(window).scrollTop();
      if (sTop > 10) {
        $(".pc_menu").addClass("curr");
      } else {
        $(".pc_menu").removeClass("curr");
      }
    });
  },
  beforeDestroy() {
    // 组件销毁前移除滚动事件监听
    // window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    restoreActiveIndex(routePath) {
      switch (routePath) {
        case "/":
          this.activeIndex = 0;
          if (this.drawer == true) {
            this.drawer = !this.drawer;
          }
          break;
        case "/aboutus":
          this.activeIndex = 1;
          if (this.drawer == true) {
            this.drawer = !this.drawer;
          }

          break;
        case "/product":
          this.activeIndex = 2;
          if (this.drawer == true) {
            this.drawer = !this.drawer;
          }
          break;
        case "/contact":
          this.activeIndex = 3;
          if (this.drawer == true) {
            this.drawer = !this.drawer;
          }
          break;
        default:
          // 默认执行代码
          if (routePath.includes("frProductDetail")) {
            console.log("进入这里");
            this.activeIndex = 2;
            if (this.drawer == true) {
              this.drawer = !this.drawer;
            }
            break;
          }
          this.activeIndex = 0;
          if (this.drawer == true) {
            this.drawer = !this.drawer;
          }
          break;
      }
    },
    handleClose(done) {
      done();
    },
    showLangage() {
      this.isShowlan = !this.isShowlan;
    },
    handleMouseLeave() {
      console.log();
      this.isShowlan = !this.isShowlan;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    handleMenu() {
      console.log("触发了点击");
    },
    toPages(type) {
      switch (type) {
        case "home":
          // 执行代码
          if (this.$router.currentRoute.path !== "/") {
            console.log();
            this.activeIndex = 0;
            // sessionStorage.setItem("activeIndex", 0);
            this.$router.push("/");
            if (this.drawer == true) {
              this.drawer = !this.drawer;
            }
            // window.location.reload();
          }
          // this.$router.push({ name: "home" });
          break;
        case "aboutus":
          // 执行代码
          if (this.$router.currentRoute.path !== "/aboutus") {
            this.activeIndex = 1;
            // sessionStorage.setItem("activeIndex", 1);
            this.$router.push("/aboutus");
            if (this.drawer == true) {
              this.drawer = !this.drawer;
            }
            // window.location.reload();
          }

          break;
        case "project":
          // 执行代码
          if (this.$router.currentRoute.path !== "/product") {
            this.activeIndex = 2;
            // sessionStorage.setItem("activeIndex", 2);
            this.$router.push("/product");
            if (this.drawer == true) {
              this.drawer = !this.drawer;
            }
            // window.location.reload();
          }
          // this.$router.push("product");
          break;
        case "contactus":
          // 执行代码
          if (this.$router.currentRoute.path !== "/contact") {
            this.activeIndex = 3;
            // sessionStorage.setItem("activeIndex", 3);
            this.$router.push("/contact");
            if (this.drawer == true) {
              this.drawer = !this.drawer;
            }
            // window.location.reload();
          }
          // this.$router.push("contact");
          break;
        default:
        // 默认执行代码
        // this.activeIndex = 0;
        // if (this.$router.currentRoute.path !== "/") {
        //   this.$router.push("/");
        //   if (this.drawer == true) {
        //     this.drawer = !this.drawer;
        //   }
        //   // window.location.reload();
        // }
      }
    },
    handleResize() {
      this.isMobile = document.documentElement.clientWidth < 768;
      this.$store.commit("SET_ISMOBILE", this.isMobile);
    },
    changeLanguage(lang) {
      // console.log("中文英文切换", lang);
      if (lang === "en") {
        this.$i18n.locale = "en";
        localStorage.setItem("languageSet", this.$i18n.locale);
        if (this.drawer == true) {
          this.drawer = !this.drawer;
        }
      } else if (lang === "zh") {
        this.$i18n.locale = "zh";
        localStorage.setItem("languageSet", this.$i18n.locale);
        if (this.drawer == true) {
          this.drawer = !this.drawer;
        }
      }
      //先用刷新页面
      // window.location.reload();

      // this.$i18n.locale == 'zh' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'zh' //设置中英文模式
      // localStorage.setItem('languageSet', this.$i18n.locale) //将用户设置存储到localStorage以便用户下次打开时使用此设置
    },
    switchto(index) {
      // if (location.pathname == this.routerlist[index]) {
      // 	return
      // }
      // this.$router.push({
      // 	path: this.routerlist[index]
      // })
    },
    handleScroll() {
      // 获取滚动条位置
      const scrollPosition = window.scrollY;

      // 根据滚动条位置判断是否添加类名
      if (scrollPosition > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
  },
};
</script>

<style lang="scss">
/* 定义过渡样式 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active for <=2.1.8 */ {
  opacity: 0;
}
.mobile_menu {
  .el-button {
    padding: 8px !important;
    font-size: 25px;
  }
  .el-button--primary {
    background: #409eff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: #409eff !important;
    border-color: #409eff !important;
  }
}

.momenuDrawer {
  background: #5c7ed7 !important;
  .el-drawer__header {
    background: #5c7ed7;
    margin-bottom: 0;
    padding-bottom: 20px;
    .el-drawer__close-btn {
      > i {
        color: white;
        font-size: 30px;
      }
    }
  }
  .el-drawer__body {
    background: #5c7ed7;
  }
  .jumpBtn {
    width: 80%;
    height: 26px;
    line-height: 30px;
    font-size: 20px;
    color: white;
    margin: 10px auto;
  }
  .qiege_line {
    width: 80%;
    margin: 20px auto;
    height: 0.5px;
    background: #f0f0f0;
  }
  .lanChanBtn {
    color: white;
  }
}
</style>
<style lang="scss" scoped>
.curr {
  background-color: rgba(102, 102, 102, 0.8);
}
.menuActive::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 3px; /* Line height */
  background-color: white; /* Line color */
  width: 30px;
  border-radius: 2px;
}
.momenuActive {
  color: #8afbf6;
}
.allheader_common {
  width: 100%;
  height: 50px;
  .allhc_menu {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    .menu_elcol {
      width: 100%;
      height: 100%;
      padding: 0 !important;
    }
    .pc_menu {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo_img {
        width: 40vw;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 30px;
        > img {
          width: 25vw;
        }
      }
      .pc_menu_con {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        height: 100%;
        color: white;
        margin-right: 50px;
        > div {
          height: 50%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          margin: 0 20px;
          white-space: nowrap;
          cursor: pointer;
        }
        .languageBtn {
          position: relative;
          .languageBox {
            top: 100%;
            right: 0;
            position: absolute;
            height: 70px;
            width: 150px;
            background: white;
            .lanBtn {
              color: black;
              display: flex;
              align-items: center;
              padding-left: 10px;
              width: 100%;
              height: 50%;
            }
            .lanBtna:hover {
              background: #59b6eb;
            }
            .lanBtnb:hover {
              background: #59b6eb;
            }
          }
        }
      }
    }

    .mobile_menu {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
      padding-right: 10px;

      .el-drawer__header {
        margin-bottom: 0 !important;
      }
    }
  }
}
.cur h1.fl img {
  max-width: 300px !important;
}
.foor_common {
  background: #409eff;
}
.c_portalResnav_main-01001002 .header1 nav li:hover > a {
  color: #409eff !important;
}
.c_portalResnav_main-01001002 .hvr-left:before {
  background: #409eff !important;
}
.footerCommon {
  @media (max-width: 768px) {
    .foot_copyright {
      white-space: normal;
    }
    .one_wechat {
      align-items: flex-start !important;
    }
  }
  @media (min-width: 768px) {
    .foot_copyright {
      white-space: nowrap;
    }
  }
  width: 100%;
  padding: 30px 100px;
  color: white;
  .foot_one {
    .one_wechat {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      // float: right;
      > img {
        height: 60px;
        width: 60px;
        margin-bottom: 10px;
      }
      > p {
        font-size: 12px;
      }
    }
    .foot_one_er {
      display: flex;
      align-items: center;
    }
    .one_box {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .circle_box {
      height: 50px;
      min-width: 50px;
      border-radius: 50%;
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      > i {
        font-size: 20px;
      }
    }
  }
  .heng_line {
    width: 100%;
    height: 2px;
    background-color: white;
    margin: 30px auto;
  }
  .foot_two {
    margin-top: 30px;
    .foot_menu > p {
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
    }
    .foot_copyright {
      font-size: 12px;
    }

    .foot_menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .su_line {
        width: 1px;
        height: 16px;
        background: white;
        margin: 0 20px;
      }
    }
  }
}
.c_portalResnav_main-01001002 .header2 .logo img {
  width: 33% !important;
}
.c_portalResnav_main-01001002 .header2 {
  background: #409eff !important;
}

.footer .text p,
.footer .text a {
  font-weight: bold;
  color: #3f3e55;

  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  font-size: 14px;
  color: #95959d;
  line-height: 24px;
  text-align: justify;
  text-decoration: none;
}
.fr .f-cb2 {
  display: flex;
  justify-content: space-around;
  padding-right: 80px;
}

.foot_section .links_list a:not(:first-child)::before {
  content: "\2022";
  /* 使用 Unicode 编码表示小黑点 */
  color: #656565;
  /* 设置黑色 */
  display: inline-block;
  /* 将伪元素转换为块级元素，以便设置宽度和高度 */
  width: 3px;
  height: 3px;
  margin-right: 15px;
  /* 设置与链接之间的间距 */
}

.foot_section .links_list a {
  font-size: 14px;
  text-decoration: none;
  position: relative;
  font-weight: bold;
  color: #656565;
  line-height: 33px;
  padding-left: 10px;
  margin-left: 3px;
  opacity: 0.6;
}

.footer {
  width: 100%;
  padding: 28px 0 37px;
  background-color: #f7f6f4;
  position: relative;
  z-index: 102;
}

.footer .content {
  position: relative;
  height: 100%;
}

.content {
  width: 1280px;
  margin: 0 auto;
}

#header {
  width: 100%;
  min-width: 1280px;
  max-width: 100%;
  margin: auto;
  position: fixed;
  top: 43px;
  left: 0px;
  right: 0px;
  z-index: 9999;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.header {
  box-sizing: border-box;
  width: 90%;
  // min-width: 1280px;
  height: 82px;
  margin: auto;
  position: relative;
  background-size: cover;
  z-index: 100;
  background-color: white;
  border-radius: 10px;
  font-size: 14px;
  -webkit-transition: all 0.4s;
  font-weight: bold;
}

.fixed-width {
  border-radius: 0;
  width: 100%;
}

.topheader {
  top: 0 !important;
  max-width: 100% !important;
}

.weChat_img {
  position: absolute;
  top: -115px;
  left: -26%;
  width: 107px;
  height: 107px;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  z-index: 99;
  opacity: 0;
  filter: Alpha(opacity=0);
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  will-change: opacity;
  -webkit-transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
  transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
  padding: 5px;
}

.socials_wrap .weChat_img img {
  width: 100%;
  height: 100%;
}

.socials_wrap img:hover + .weChat_img {
  opacity: 1;
  filter: Alpha(opacity=100);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
}

#w_grid-1583825877988 {
  left: 0px !important;
  right: 0px !important;
}

.c_portalResnav_main-01001002 .header1 .nav-sub.active {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}

.c_portalResnav_main-01001002 .header1 .nav-sub.active .n-nav-sub {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: all 0.2s 0.3s;
  transition: all 0.2s 0.3s;
}
</style>