<template>
  <div class="homeContent">
    <div class="head_box"></div>
    <div class="aboutFurui">
      <div class="about_one">
        <div class="blue_piece"></div>
        <p>{{ $t("user.aboustus") }}</p>
      </div>
      <div class="about_two">{{ $t("user.chongqingcontent1") }}</div>
      <div class="about_three"></div>
      <div class="about_four">
        {{ $t("user.chongqingcontent2") }}
      </div>
    </div>
    <div class="home_three">
      <div class="three_content">
        <el-row :gutter="10">
          <el-col :xs="0" :sm="12">
            <div class="ht_left">
              <div>{{ $t("user.chongqingcontent3") }}</div>
              <div class="heng_line"></div>
              <div class="start_year"><span class="es_font">2009</span>年</div>
              <div>{{ $t("user.chongqingcontent4") }}</div>
              <div class="heng_line"></div>
              <div><span class="es_font">1000</span>万双</div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12">
            <div class="ht_right">
              <video width="100%" height="" autoplay controls>
                <source src="../assets/furuiImg/006.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="home_last">
      <div class="last_content">
        <div class="last_one">
          <div class="blue_piece"></div>
          {{ $t("user.News") }}
        </div>
        <div class="last_two">
          <span>{{ $t("user.focusUpdate") }}</span>
        </div>
        <div class="heng_line"></div>
        <div class="news_box">
          <el-row :gutter="10" class="news_boxel">
            <el-col :xs="24" :sm="12" class="news_left">
              <div class="block">
                <el-carousel>
                  <el-carousel-item v-for="item in newsSwipper" :key="item.id">
                    <!-- <h3 class="small">{{ item }}</h3> -->
                    <div class="botMsg">
                      <span>{{ item.msg }}</span>
                    </div>
                    <img :src="item.url" alt="" />
                  </el-carousel-item>
                </el-carousel></div
            ></el-col>
            <el-col :xs="0" :sm="12" class="news_right">
              <div class="newsr_box">
                <div class="newsrb_item">
                  <div class="ni_left">
                    <span>23</span>
                    <div>2024-6</div>
                  </div>
                  <div class="ni_right">
                    <p>{{ $t("user.news1") }}</p>
                    <div>
                      {{ $t("user.news2") }}
                    </div>
                  </div>
                </div>
                <div class="newsrb_item">
                  <div class="ni_left">
                    <span>19</span>
                    <div>2024-6</div>
                  </div>
                  <div class="ni_right">
                    <p>{{ $t("user.news3") }}</p>
                    <div>
                      {{ $t("user.news4") }}
                    </div>
                  </div>
                </div>
                <div class="newsrb_item">
                  <div class="ni_left">
                    <span>30</span>
                    <div>2024-6</div>
                  </div>
                  <div class="ni_right">
                    <p>{{ $t("user.news5") }}</p>
                    <div>
                      {{ $t("user.news6") }}
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { achievementPic, projuct, article } from "@/api";

export default {
  data() {
    return {
      isMobile: this.$store.state.isMobile,
      // 保存定时器引用
      timer: null,
      count: 0,
      count2: 0,
      count3: 0,
      isCounting: false, // 防止多次计数
      hasScrolledIntoView: false,
      newData: [],
      newsSwipper: [
        {
          id: 0,
          url: require("@/assets/furuiImg/3news.png"),
          msg: "技术研发交流大会",
        },
        {
          id: 1,
          url: require("@/assets/furuiImg/3news.png"),
          msg: "二次技术交流大会",
        },
        {
          id: 2,
          url: require("@/assets/furuiImg/3news.png"),
          msg: "三次技术交流大会",
        },
        {
          id: 3,
          url: require("@/assets/furuiImg/3news.png"),
          msg: "交流大会",
        },
      ],
    };
  },
  mounted() {
    console.log(this.isMobile);
    // 组件挂载后添加滚动事件监听器
    window.addEventListener("scroll", this.checkScroll);
    // 初始检查滚动位置

    // swiper 轮播图功能

    // 产品数据
    // this.project();

    // 新闻数据
    // this.newa();
  },
  methods: {
    newa() {
      article()
        .then((res) => {
          if (res.status == 200) {
            this.newData = res.data;
          }
        })
        .catch((req) => {
          this.$alert(req.message, " ", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$message({
                type: "info",
                message: `action: ${action}`,
              });
            },
          });
        });
    },
    project() {
      projuct().then((res) => {
        console.log(res, "ssssssss");
        if (res.status == 200) {
          this.dataList = res.data.data;
        } else {
          this.$alert(res.statusText, " ", {
            confirmButtonText: "确定",
            callback: (action) => {
              this.$message({
                type: "info",
                message: `action: ${action}`,
              });
            },
          });
        }
      });
    },
    swiperBanner() {
      var index = 0;
      //效果
      function ChangeImg() {
        var a = document.getElementsByClassName("productLi");
        if (index >= a.length) index = 0;
        for (var i = 0; i < a.length; i++) {
          // console.log(i)
          if (a[i]) {
            a[i].style.display = "none";
          }
        }
        if (a[index]) {
          a[index].style.display = "block";
        }
        if (a[index + 1]) {
          a[index + 1].style.display = "block";
        }
        if (a[index + 2]) {
          a[index + 2].style.display = "block";
        }
        if (a[index + 3]) {
          a[index + 3].style.display = "block";
        }
        index++;
      }
      //设置定时器，每隔两秒切换一张图片
      this.timer = setInterval(ChangeImg, 3000);
    },
    checkScroll() {
      //数字倒计时显示

      this.$nextTick(() => {
        if (this.$refs.target) {
          const target = this.$refs.target;
          const rect = target.getBoundingClientRect();
          // 判断元素是否在视口内
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            if (!this.hasScrolledIntoView) {
              this.hasScrolledIntoView = true;
              // 这里可以执行其他操作，例如开始动画等
              const target = 15; // 目标数字
              const target2 = 100; // 目标数字
              const target3 = 30; // 目标数字
              let current = this.count;
              let current2 = this.count2;
              let current3 = this.count3;
              const interval = setInterval(() => {
                if (current < target) {
                  current += 1;
                  this.count = current;
                } else {
                  clearInterval(interval);
                }
              }, 100); // 每100毫秒增加1
              const interval2 = setInterval(() => {
                if (current2 < target2) {
                  current2 += 1;
                  this.count2 = current2;
                } else {
                  clearInterval(interval2);
                }
              }, 100); // 每100毫秒增加1

              const interval3 = setInterval(() => {
                if (current3 < target3) {
                  current3 += 1;
                  this.count3 = current3;
                } else {
                  clearInterval(interval3);
                }
              }, 100); // 每100毫秒增加1
            }
          } else {
            if (this.hasScrolledIntoView) {
              this.hasScrolledIntoView = false;
            }
          }
        }
      });
    },
  },
  beforeDestroy() {
    // 在组件销毁之前停止观察器
    // if (this.observer) {
    // this.items.forEach((item, index) => {
    // const target = this.$refs['box' + index][0];
    // this.observer.unobserve(target);
    // });
    // }
    // 组件销毁前清除定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style scoped lang="scss">
@media (max-width: 768px) {
  .head_box {
    height: 270px !important;
  }
}
.head_box {
  width: 100%;
  background: url(../assets/furuiImg/1banner.webp);
  height: 520px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.blue_piece {
  height: 11px;
  width: 5px;
  background-color: blue;
  margin-right: 10px;
}
.heng_line {
  width: 100%;
  height: 1px;
  background-color: rgb(181, 181, 181);
  margin: 20px 0;
}
.aboutFurui {
  width: 80vw;
  margin: 80px auto;
  .about_one {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .about_two {
    font-size: 30px;
    font: black;
  }
  .about_three {
    width: 100%;
    height: 1px;
    margin: 30px auto;
    background-color: rgb(167, 165, 165);
  }
  .about_four {
    line-height: 3;
    text-indent: 4ch;
  }
}
.home_three {
  width: 100%;
  background: url(../assets/furuiImg/background.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
  .three_content {
    width: 80vw;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .ht_left {
      width: 30vw;

      .start_year {
        margin-bottom: 20px;
      }
      .es_font {
        color: #409eff;
        font-size: 36px;
      }
    }
    .ht_right {
    }
  }
}
.home_last {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 80px;
  .last_content {
    width: 80vw;
    margin: 0 auto;

    .last_one {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 20px;
    }
    .last_two {
      > span {
        padding: 10px 30px 10px 0;
        font-size: 32px;
        font-weight: 400;
        background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 1),
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0) 50%,
            rgba(255, 255, 255, 0)
          ),
          radial-gradient(
            circle at 25% 50%,
            rgba(0, 0, 0, 0.2) 10%,
            rgba(0, 0, 0, 0) 11%
          ),
          radial-gradient(
            circle at 75% 50%,
            rgba(0, 0, 0, 0.2) 10%,
            rgba(0, 0, 0, 0) 11%
          );
        background-size: 8px 8px;
        background-position: 0 0, 0 0;
        background-repeat: repeat;
      }
    }
    .news_box {
      .news_boxel {
        display: flex;
        justify-content: space-between;
      }
      .news_left {
        // margin-right: 30px;
        .block {
          width: 100%;
          height: 100%;
          position: relative;
          .botMsg {
            position: absolute;
            bottom: 0;
            background: #565454;
            opacity: 0.7;
            color: white;
            width: 100%;
            height: 50px;
            line-height: 50px;
            overflow: hidden;
            white-space: nowrap;
            text-indent: 2ch;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .news_right {
        .newsr_box {
          width: 100%;

          .newsrb_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 90px;
            width: 100%;
            margin-bottom: 20px;
            .ni_left {
              height: 100%;
              background: #75b6f5;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              color: white;
              margin-right: 10px;
              padding: 0 20px;
              > span {
                font-size: 30px;
                font-weight: bold;
                line-height: 1;
              }
              > div {
                white-space: nowrap;
              }
            }
            .ni_right {
              > p {
                font-size: 16px;
                color: #6eb3f8;
                margin-bottom: 10px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              > div {
                font-size: 12px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}
</style>