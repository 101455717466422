import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: false,
    currentPath: '/',
    activeIndex: 0
  },
  getters: {
  },
  mutations: {
    SET_ISMOBILE(state, ismobile) {
      state.isMobile = ismobile
    },
    SET_CURRENTPATH(state, currentPath) {
      state.currentPath = currentPath
    },
    SET_ACTIVEINDEX(state, newActiveIndex) {
      console.log("修改了activeindex")
      state.activeIndex = newActiveIndex
    },
  },
  actions: {
  },
  modules: {
  }
})
