module.exports = {
	language: {
		name: '中文'
	},
	user: {
		login: 'Home',
		aboustus: 'About us',
		Products: 'Products',
		News: 'News',
		Feedback: 'Feedback',
		Contactus: 'Contact us',
		Language: 'Language',
		Chinese: 'Chinese',
		English: 'English',
		projectList: 'Product List',
		projectDetail: 'product details',
		Productkeywords: 'Product keywords',
		search: 'search',
		focusUpdate: 'Focus on real-time updates of the company',
		searchCon: 'Please enter your search',
		homesynopsis: `Chongqing Like Industry and Trade Co., Ltd. is a medium-sized motorcycle manufacturer located in Chongqing, China'
		s motorcycle production base.All the key members have more than 15 years of experience in leading companies,
		which enables us to provide you with a professional and efficient service.
		`,
		enterFurui: 'Entering Fury',
		Chongqing: `Chongqing Like Industry and trade Co., LTD`,
		chongqingfu: `Different, win-win!`,
		chongqingcontent1: `Fully is an enterprise integrating R&D production, sales with logistics`,
		chongqingcontent2: `Fully(Fujian)Electronic Co.,Ltd. located in Chengxiang District Putian City was founded in 2009,a professional shoe light manufacturing company integrating R&D,design,production,sales and service,with an yearly output of 10million pairs of electronic shoe lights. Rich experienced R&D and quality guarantee teams,and modern production equipment make the company provide the capability of developing unique competitive products,and service in performance and price as well as high efficiency. At present,fully electronics has established strategic cooperation with many well-konwn shoe manufacturers both at home and abroad,providing shoe lights of various styles,charging flashing lights and display and so on.Rich products and competitive prices,have won the recognition and overseas market .Fully Electronics will keep going,on upholding the business philosophy of"Customer first,quality first, scientific innovation,integrity-based"and dedicating to providing customers,with satisfactory products and vlaue-added servi`,
		chongqingcontent3: `Furui's Established time`,
		chongqingcontent4: `Annual production capacity`,
		chongqingcontent5: `Modern Equipment`,
		chongqingcontent6: 'Advanced production equipment and automated production lines',
		chongqingcontent7: 'Honor and Qualification',
		chongqingcontent8: "Obtained Fujian Province's Specialized and New Enterprise withover 100 domestic and foreign patents",
		chongqingcontent9: 'Chongqing Like Industry and Trade Co., LTD All rights reserved',
		chongqingcontent10: 'Chongqing ICP for 2021004270',
		chongqingcontent11: 'Technical support: China Enterprise Power Chongqing',
		chongqingcontent12: 'Chongqing public network Anbei 50011302001512',
		chongqingcontent13: 'relation',
		chongqingcontent14: 'Furui Technology Electronics Co., Ltd., located in Chengxiang District, Putian City, was founded in 2009. It integrates research and development, design, production, sales, and serviceA professional shoe light production company with an annual production capacity of up to 10 million pairs of electronic shoe lights and rich industry experience in technology research and developmentWith a quality assurance team and modern production equipment, we develop products and services that are uniquely cost-effective and efficient for our customers. at presentFurui Technology has established strategic supply and marketing partnerships with several well-known shoe manufacturers at home and abroad, providing a variety of shoe lights and charging flashlights in different stylesAnd display boards and other products, with a wide range of products and discounted prices, have won the recognition of customers and overseas markets for the company. Furui Technology will continue to upholdAdhering to the business philosophy of "customer first, quality first, scientific innovation, and integrity-based", we are dedicated to providing customers with satisfactory products and value-added services.',
		chongqingcontent15: 'phone',
		chongqingcontent16: 'mailbox',
		chongqingcontent17: 'in regard to',
		chongqingcontent18: 'product',
		chongqingcontent19: 'journalism',
		chongqingcontent20: 'online message',
		chongqingcontent21: 'Contact Us',
		chongqingcontent22: 'Furui Technology has established strategic supply and marketing partnerships with multiple well-known shoe manufacturers at home and abroad, providing a variety of shoe lights, charging flashlights, display boards, and other products. With rich products and favorable prices, the company has won recognition from customers and overseas markets. Furui Technology will continue to adhere to the business philosophy of "customer first, quality first, scientific innovation, and integrity-based", and wholeheartedly provide customers with satisfactory products and value-added services.',
		chongqingcontent23: 'Please enter your mobile phone',
		chongqingcontent24: 'Please enter your email address',
		chongqingcontent25: 'submit',
		chongqingcontent26: 'company profile',
		chongqingcontent27: 'qualification honor',
		chongqingcontent28: 'enterprise culture',
		chongqingcontent29: 'contact information',
		chongqingcontent30: '2nd Floor, Building 2, No. 1366 Zhulin Road, Huating Town, Chengxiang District, Putian City, Fujian Province',
		chongqingcontent31: 'Map Location',
		chongqingcontent32: 'WeChat Account',
		project1: 'Shoe lights, childrens shoes, flash lights, shoe accessories, sole lights, electronic LED shoe lights, sole lights, shoe accessories, handicrafts',
		project2: 'LED luminous rainbow shoe lights, sports shoe lights accessories, projection lights, charging lights, electronic lights, shoe accessories, luminous shoe lights',
		project3: 'Creative pentagram lamp for shoe lights, childrens shoe accessories, flash LED shoe lights, fashionable and trendy shoe lights wholesale',
		project4: 'Cross border wholesale LED colorful glowing shoe lights, childrens shoes, shining cool shoe lights, flashing long line shoe lights, light strips',
		project5: 'Manufacturer direct sales of fashionable LED lights, colorful luminous shoe lights, childrens luminous shoe lights, sports shoe accessories, light strips, light strips',
		project6: 'Factory direct sales of fashionable LED lights, music lights, rechargeable music lights, colorful luminous shoe lights, shoe accessories wholesale',
		project7: 'New shoelace pendant light cartoon shoe buckle light LED sunflower buckle shoe flower flashing light butterfly hole shoe flower',
		project8: 'Manufacturer wholesale massage wireless charging shoe light flashing shoe light strip LED rainbow shoe light emitting shoe light',
		project9: 'LED luminous rainbow shoe lights, sports shoe lights accessories, projection lights, charging lights, electronic lights, shoe accessories, luminous shoe lights',
		project10: 'New LED luminous shoelaces, colorful woven shoelaces, sports shoes, luminous shoelaces, directly supplied by manufacturers for wholesale',
		project11: 'New shoelace pendant light cartoon shoe buckle light LED sunflower buckle shoe flower flashing light butterfly hole shoe flower',
		project12: 'Manufacturer supplies round headed transparent white light direct insertion LED beads, straw hat large cup LED electronic beads',
		project13: 'Factory Direct Supply: Tuobu CR2032 Lithium Manganese Dioxide Button Battery, Button Battery Wholesale',
		project14: 'Factory direct mini LED key light, UV banknote detector light, portable lighting, small flashlight, key, night light',
		project15: 'Factory Direct Supply: Tuobu CR2032 Lithium Manganese Dioxide Button Battery, Button Battery Wholesale',
		project16: 'Buddha bead counter LED digital luminous decompression dial timer manual decompression dial counting broadcasting integrated',
		project17: 'LED Atmosphere Magic Ball Light Sports Shoe Light Accessories Luminous Packaging Decoration Flash Electronic Light Colorful Light Wholesale',
		news1: 'Rui Technology breaks the annual production record and achieves a production of millions of pairs of shoe lights',
		news2: 'The company plans to further expand its production scale in the coming years and continue to optimize its products and services to meet a wider market demand. The company will continue to adhere to the development philosophy of innovation driven and quality-oriented, and is committed to becoming a leader in the global shoe lighting industry.',
		news3: 'Rui Technology was awarded the title of "Annual Best Supplier"',
		news4: `The company was awarded the title of "Best Supplier of the Year" at the recent Footwear Supply Chain Conference. This honor is a high recognition of the company's outstanding performance and commitment to customers over the past year. Thanks to its outstanding performance in product quality, technological innovation, customer service, and market response speed, Furui Technology.`,
		news5: 'Furui Technology launches a new generation of intelligent shoe light products',
		news6: 'The company recently announced the launch of a series of new generation intelligent shoe lighting products, which adopt the latest intelligent control system and can provide users with a more personalized and convenient lighting experience. The newly launched smart shoe light product has multiple color and brightness options, and users can easily control the lighting through a mobile application.',
	}
}