<template>
  <div class="proDetail">
    <div class="prodetail_header">
      <div class="ph_box"></div>
    </div>
    <div class="proDetail">
      <div class="proDetail_title">{{ $t("user.projectDetail") }}</div>
      <div class="proDetail_list">
        <img
          class="img_item"
          v-for="(image, index) in images"
          :key="index"
          @click="showFullImage(image.src)"
          :src="image.src"
        />
      </div>
      <!-- 模态框，显示全尺寸图片 -->
      <div v-if="fullImageUrl" class="modal" @click="fullImageUrl = null">
        <img :src="fullImageUrl" alt="Full Image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "frProjectDetail",
  data() {
    return {
      images: [],
      fullImageUrl: null,
    };
  },
  created() {
    // 当组件创建时，获取详情数据
    this.fetchDetailData(this.$route.params.id);
    // console.log();
    this.loadImages(this.$route.params.id);
    console.log(this.images);
  },
  methods: {
    closeImg() {},
    showFullImage(imgUrl) {
      this.fullImageUrl = imgUrl;
    },
    //wx52e766302bb39bfe
    loadImages(type) {
      let imgContext = null;
      switch (type) {
        case "01":
          imgContext = require.context(
            "@/assets/furuiImg/lights/1/",
            false,
            /\.jpg$|\.png$/
          );
          break;

        case "02":
          imgContext = require.context(
            "@/assets/furuiImg/lights/2/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "03":
          imgContext = require.context(
            "@/assets/furuiImg/lights/3/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "04":
          imgContext = require.context(
            "@/assets/furuiImg/lights/4/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "05":
          imgContext = require.context(
            "@/assets/furuiImg/lights/5/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "06":
          imgContext = require.context(
            "@/assets/furuiImg/lights/6/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "07":
          imgContext = require.context(
            "@/assets/furuiImg/lights/7/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "08":
          imgContext = require.context(
            "@/assets/furuiImg/lights/8/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "09":
          imgContext = require.context(
            "@/assets/furuiImg/lights/9/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "10":
          imgContext = require.context(
            "@/assets/furuiImg/lights/10/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "11":
          imgContext = require.context(
            "@/assets/furuiImg/lights/11/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "12":
          imgContext = require.context(
            "@/assets/furuiImg/lights/12/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "13":
          imgContext = require.context(
            "@/assets/furuiImg/lights/13/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "14":
          imgContext = require.context(
            "@/assets/furuiImg/lights/14/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "15":
          imgContext = require.context(
            "@/assets/furuiImg/lights/15/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "16":
          imgContext = require.context(
            "@/assets/furuiImg/lights/16/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        case "17":
          imgContext = require.context(
            "@/assets/furuiImg/lights/17/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        default:
          imgContext = require.context(
            "@/assets/furuiImg/lights/1/",
            false,
            /\.jpg$|\.png$/
          );
          break;
        //break;
      }
      // 使用require.context读取所有jpg/png图片
      this.images = imgContext.keys().map((path) => {
        // 使用require加载图片并返回其路径
        return {
          src: imgContext(path),
        };
      });
    },
    fetchDetailData(id) {
      // 根据id获取详情数据的逻辑
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ph_box {
    height: 270px !important;
  }
}
.proDetail {
  width: 100%;
  .prodetail_header {
    width: 100%;

    background: url(../assets/furuiImg/probanner.webp);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    .ph_box {
      width: 80vw;
      height: 520px;
      margin: 0 auto;
    }
  }
  .proDetail {
    width: 100%;

    .proDetail_title {
      width: 80vw;
      margin: 50px auto;

      text-align: center;
      font-size: 30px;
    }

    .proDetail_list {
      .overlay {
        position: fixed; /* 确保遮罩层覆盖整个页面 */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7); /* 遮罩层颜色和透明度 */
        z-index: 1000; /* 确保遮罩层在图片之下 */
      }
      width: 80vw;
      margin: 50px auto 80px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 25%);
      .img_item {
        width: 18vw;
        margin-bottom: 30px;
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out,
          height 0.3s ease-in-out;
        cursor: pointer;
      }
    }
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }

    .modal img {
      max-width: 100%;
      max-height: 100%;
      position: relative;
      z-index: 1001;
    }
  }
}
</style>