module.exports = {
	language: {
		name: 'English'
	},
	user: {
		login: '首页',
		aboustus: '关于福瑞',
		Products: '产品中心',
		News: '新闻动态',
		Feedback: '在线留言',
		Contactus: '联系我们',
		Language: '语言版本',
		Chinese: '中文',
		English: '英文',
		projectList: '产品列表',
		projectDetail: '产品详情',
		Productkeywords: '产品关键词',
		search: '搜索',
		focusUpdate: '聚焦公司实时动态',
		searchCon: '请输入搜索内容',
		homesynopsis: `福瑞科技电子有限公司是位于中国福建莆田。 所有的主要成员在领先企业中都拥有超过15年的经验，这使我
		们能够为您提供专业，高效的服务。`,
		enterFurui: '走进福瑞',
		Chongqing: '福瑞科技电子有限公司',
		chongqingfu: '与众不同，互利双赢！',
		chongqingcontent1: '集研发、设计、生产、销售、服务于一体',
		chongqingcontent2: '莆田市城厢区福瑞科技电子有限公司，创立于2009年，集研发、设计、生产、销售、服务于一体的专业生产鞋灯公司，年产能可达1000万双电子鞋灯，拥有行业经验丰富的技术研发 ，和品质保障团队，配有现代化生产设备，为客户开发独具高性价、高效率的产品和服务。目前，福瑞科技与国内外多家知名鞋子生产企业，建立供销战略合作关系，提供款式多样的鞋灯、充电闪灯及展示板等产品，丰富的产品和优惠的价格，为公司赢得了客户的认可和海外市场。福瑞科技将继续秉承“顾客至上、品质第一、科学创新、诚信为本”的经营理念，竭诚为客户提供满意的产品和超值服务。',
		chongqingcontent3: '福瑞科技创立于',
		chongqingcontent4: '年产能可达',
		chongqingcontent5: '现代化设备',
		chongqingcontent6: '先进的生产设备和自动化生产线',
		chongqingcontent7: '荣誉资质',
		chongqingcontent8: '福建省“专精特新”中小企业，拥有 100 多项国内外专利',
		chongqingcontent9: '福瑞科技电子有限责任公司@版权声明',
		chongqingcontent10: '闽ICP备1234567号',
		chongqingcontent11: '@CopyRight 2009-2014 版本所有',
		chongqingcontent12: '渝公网安备 50011302001512 号',
		chongqingcontent13: '联系',
		chongqingcontent14: '莆田市城厢区福瑞科技电子有限公司，创立于2009年，集研发、设计、生产、销售、服务于一体的专业生产鞋灯公司，年产能可达1000万双电子鞋灯，拥有行业经验丰富的技术研发，和品质保障团队，配有现代化生产设备，为客户开发独具高性价、高效率的产品和服务。目前，福瑞科技与国内外多家知名鞋子生产企业，建立供销战略合作关系，提供款式多样的鞋灯、充电闪灯及展示板等产品，丰富的产品和优惠的价格，为公司赢得了客户的认可和海外市场。福瑞科技将继续秉承“顾客至上、品质第一、科学创新、诚信为本”的经营理念，竭诚为客户提供满意的产品和超值服务。',
		chongqingcontent15: '电话',
		chongqingcontent16: '邮箱',
		chongqingcontent17: '关于',
		chongqingcontent18: '产品',
		chongqingcontent19: '新闻',
		chongqingcontent20: '在线留言',
		chongqingcontent21: '联系方式',
		chongqingcontent22: '福瑞科技与国内外多家知名鞋子生产企业，建立供销战略合作关系，提供款式多样的鞋灯、充电闪灯及展示板等产品，丰富的产品和优惠的价格，为公司赢得了客户的认可和海外市场。福瑞科技将继续秉承“顾客至上、品质第一、科学创新、诚信为本”的经营理念，竭诚为客户提供满意的产品和超值服务。',
		chongqingcontent23: '请输入你的手机',
		chongqingcontent24: '请输入你的邮箱',
		chongqingcontent25: '提交',
		chongqingcontent26: '企业介绍',
		chongqingcontent27: '资质荣誉',
		chongqingcontent28: '企业文化',
		chongqingcontent29: '联系方式',
		chongqingcontent30: '福建省莆田市城厢区华亭镇竹林路1366号2号楼第二层',
		chongqingcontent31: '地图位置',
		chongqingcontent32: '微信公众号',
		project1: '鞋灯童鞋闪光灯鞋配件鞋底灯电子lLED鞋灯 鞋底灯鞋辅料工艺品',
		project2: 'LED发光七彩鞋灯运动鞋灯配件投影灯充电灯电子灯鞋辅件发光鞋灯',
		project3: '鞋灯创意五角星灯童鞋 鞋配件闪光灯LED鞋灯时尚潮流鞋灯批发',
		project4: '跨境批发LED炫彩发光鞋灯 儿童鞋发光炫酷鞋灯闪光长线鞋灯灯带',
		project5: '厂家直销时尚LED灯炫彩发光鞋灯儿童发光鞋灯运动鞋配件灯条灯带',
		project6: '厂家直销时尚LED灯音乐灯充电音乐灯炫彩发光鞋灯鞋辅件批发',
		project7: '新款鞋带挂件灯卡通鞋扣亮灯LED太阳花扣鞋花闪灯蝴蝶洞洞鞋花',
		project8: '厂家批发按摩无线充电鞋灯闪光鞋灯条LED七彩鞋灯发光鞋灯',
		project9: 'LED发光七彩鞋灯运动鞋灯配件投影灯充电灯电子灯鞋辅件发光鞋灯',
		project10: '新款LED发光鞋带夜光七彩编织鞋带运动鞋发光鞋带厂家直供批发',
		project11: '新款鞋带挂件灯卡通鞋扣亮灯LED太阳花扣鞋花闪灯蝴蝶洞洞鞋花',
		project12: '厂家供应圆头透明白光直插LED灯珠草帽大杯发光二极管电子灯珠',
		project13: '工厂直供拓步CR2032锂二氧化锰扣式电池纽扣式电池批发',
		project14: '工厂直销迷你LED钥匙灯紫外线验钞灯携带照明小手电筒钥匙小夜灯',
		project15: '工厂直供拓步CR2032锂二氧化锰扣式电池纽扣式电池批发',
		project16: '佛珠计数器LED数显夜光解压拨珠计时器手动减压拨珠记数播音一体',
		project17: 'LED氛围魔球灯运动鞋灯配件发光包装饰闪光灯电子灯七彩灯批发',
		news1: '瑞科技突破年度生产纪录，实现千万双鞋灯产量',
		news2: '公司计划在未来几年内进一步扩大生产规模，并继续优化其产品和服务，以满足更广泛的市场需求。公司将继续坚持创新驱动、质量为本的发展理念，致力于成为全球鞋灯行业的领跑者.',
		news3: '瑞科技荣获“年度最佳供应商”称号',
		news4: '公司在近日举行的鞋业供应链大会上，荣获“年度最佳供应商”称号。这一荣誉是对公司在过去一年中卓越表现和对客户承诺的高度认可。福瑞科技凭借其在产品质量、技术创新、客户服务以及市场响应速度等方面的出色表现…',
		news5: '福瑞科技推出新一代智能鞋灯产品',
		news6: '公司近日宣布推出一系列新一代智能鞋灯产品，这些产品采用了最新的智能控制系统，能够为用户提供更加个性化和便捷的照明体验。新推出的智能鞋灯产品具有多种颜色和亮度选择，用户可以通过手机应用程序轻松控制灯光.',

	}
}