<template>
	<div id="w_grid-1564456455396" class="w_grid-000" data-tmpleditable="editable">
		<div class="w_grid signal">
			<div class="e_box e_box-000 p_gridbox">
				<div id="content_box-1564456455396-0" class="e_box e_box-000 d_gridCell_0 p_gridCell ND_empty"
					data-editablecontent="1564456455396">
					<div id="w_grid-1583681625027" class="w_grid-000">
						<div class="w_grid signal">
							<div class="e_box e_box-000 p_gridbox">
								<div id="content_box-1583681625027-0" class="e_box e_box-000 d_gridCell_0 p_gridCell">
									<div id="c_portalResPublic_codeContainer-1583681625087"
										class="c_portalResPublic_codeContainer-01001">
										<div class="e_box">
											<div class="in_about_b hxd">
												<img class="p_bgBox" src="@/assets/picture/bb3.jpg" alt="Green Stone ">
												<div class="text">
													<!-- 标题开始 -->

													<p class="bannerTitle en_font_bold">Looking
														forward to your joining next
													</p>
													<!-- 标题结束 -->

													<!-- banner概要开始 -->
													<article class="summry" style="display:none;">Looking forward to
														your joining next</article>
													<p class="banner_en en_font">Be Different,
														Be Success !</p>
													<!-- banner概要结束 -->
												</div>
												<div class="in_about_in "></div>
											</div>
										</div>
									</div>
									<div id="w_grid-1583681625037" class="w_grid-000">
										<div class="w_grid signal">
											<div class="e_box e_box-000 p_gridbox">
												<div id="content_box-1583681625037-0"
													class="e_box e_box-000 d_gridCell_0 p_gridCell">
													<div id="w_grid-1583681625047" class="w_grid-001">
														<div class="w_grid ">
															<div class="e_box e_box-000 p_gridbox">
																<div id="content_box-1583681625047-0"
																	class="e_box e_box-000 d_gridCell_0 p_gridCell">
																	<div id="w_grid-1583681625057" class="w_grid-000"
																		data-vhide768="true" data-vhide1024="true">
																		<div class="w_grid signal">
																			<div class="e_box e_box-000 p_gridbox">
																				<div id="content_box-1583681625057-0"
																					class="e_box e_box-000 d_gridCell_0 p_gridCell">
																					<div id="w_common_text-1583681625077"
																						class="reset_style">
																						<div>
																							<h6><span
																									style="color:#333333"><span
																										style="font-size:30px"><span
																											style="font-family:arial,helvetica,sans-serif">Categroies</span></span></span>
																							</h6>
																						</div>
																					</div>
																					<div id="w_common_text-1583681625082"
																						class="reset_style">
																						<div>
																							<h5><span
																									style="color:#333333"><span
																										style="font-size:18px">OUR
																										PRODUCTS</span></span>
																							</h5>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div id="c_portalResProduct_category-1583681625092"
																		class="c_portalResProduct_category-01001">
																		<div class="categrayBox">
																			<!-- 移动端按钮开始 -->
																			<div class="moveBtn">
																				Categroies
																				<i class="iconfont">&#xe6af;</i>
																			</div>
																			<!-- 移动端按钮结束 -->

																			<!-- 内容主体开始 -->
																			<div class="contentBox">
																				<!-- 收回按纽开始 -->
																				<div class="backBtn">
																					<i class="iconfont btn">&#xe6ae;</i>
																				</div>
																				<!-- 收回按纽结束 -->
																				<!-- 一级标题开始 -->
																				<ul class="content">
																					<!-- 循环一级开始 -->
																					<li csass="cateListBox">
																						<!-- 一级标题开始 -->
																						<h3 class="yjTitle link">
																							<a class="yjLink cateName en_font_bold"
																								href="index3.html">DIRT
																								BIKE</a>
																							<!-- 判断一级有无子集开始 -->
																							<!-- 判断一级有无子集结束 -->
																						</h3>
																						<!-- 判断一级有无子集开始 -->
																						<!-- 判断一级有无子集结束 -->
																						<!-- 一级标题结束 -->
																					</li>
																					<li csass="cateListBox">
																						<!-- 一级标题开始 -->
																						<h3 class="yjTitle link">
																							<a class="yjLink cateName en_font_bold"
																								href="index4.html">PIT
																								BIKE</a>
																							<!-- 判断一级有无子集开始 -->
																							<!-- 判断一级有无子集结束 -->
																						</h3>
																						<!-- 判断一级有无子集开始 -->
																						<!-- 判断一级有无子集结束 -->
																						<!-- 一级标题结束 -->
																					</li>
																					<li csass="cateListBox">
																						<!-- 一级标题开始 -->
																						<h3 class="yjTitle link">
																							<a class="yjLink cateName en_font_bold"
																								href="index5.html">MORE</a>
																							<!-- 判断一级有无子集开始 -->
																							<!-- 判断一级有无子集结束 -->
																						</h3>
																						<!-- 判断一级有无子集开始 -->
																						<!-- 判断一级有无子集结束 -->
																						<!-- 一级标题结束 -->
																					</li>
																					<li csass="cateListBox">
																						<!-- 一级标题开始 -->
																						<h3 class="yjTitle link">
																							<a class="yjLink cateName en_font_bold"
																								href="index8.html">配件</a>
																							<!-- 判断一级有无子集开始 -->
																							<!-- 判断一级有无子集结束 -->
																						</h3>
																						<!-- 判断一级有无子集开始 -->
																						<!-- 判断一级有无子集结束 -->
																						<!-- 一级标题结束 -->
																					</li>
																					<!-- 循环一级结束 -->
																				</ul>
																				<!-- 一级标题结束 -->
																			</div>
																			<!-- 内容主体结束 -->
																		</div>

																	</div>
																</div>
																<div id="content_box-1583681625047-1"
																	class="e_box e_box-000 d_gridCell_1 p_gridCell">
																	<div id="w_grid-1583681625067" class="w_grid-000">
																		<div class="w_grid signal">
																			<div class="e_box e_box-000 p_gridbox">
																				<div id="content_box-1583681625067-0"
																					class="e_box e_box-000 d_gridCell_0 p_gridCell">
																					<div id="c_portalResBreadcrumb_nav-1583681625097"
																						class="c_portalResBreadcrumb_nav-01001">
																						<!--homePage  首页-->
																						<div class="e_box e_box-000 p_breadcrumbList"
																							data-ename="面包屑导航">

																							<div
																								class="e_breadcrumb e_breadcrumb-001 p_breadcrumbFirst">
																								<span class="weizhi"><i
																										class="iconfont"></i>Your
																									location：</span>
																								<a href="index.html">
																									<div class="font">
																										<span>Home</span>
																									</div>
																								</a>
																							</div>
																							<!--compositePage 应用综合页对象-->
																							<!--navBreadcrumb 应用数据集合对象（list集合）-->

																							<div
																								class="e_title e_GuideLabel-001 p_end">
																								<div class="font">
																									<span
																										class="i_separator">/</span>
																								</div>
																							</div>
																							<div
																								class="e_breadcrumb e_breadcrumb-001 p_breadcrumb contentBox">
																								<a href="index1.html">
																									<div class="font">
																										<span>产品中心</span>
																									</div>
																								</a>
																							</div>
																							-->
																							<div
																								class="e_title e_GuideLabel-001 p_end">
																								<div class="font">
																									<span
																										class="i_separator">/</span>
																								</div>
																							</div>
																							<div
																								class="e_breadcrumb e_breadcrumb-001 p_breadcrumb contentBox">
																								<a href="index8.html">
																									<div class="font">
																										<span>配件</span>
																									</div>
																								</a>
																							</div>
																							-->
																							<!-- currentPage 当前页对象-->
																							<div
																								class="e_title e_GuideLabel-001 p_end">
																								<div class="font">
																									<span
																										class="i_separator">/</span>
																								</div>
																							</div>
																							<div
																								class="e_breadcrumb e_breadcrumb-000 p_breadcrumbCurrent">
																								<div class="font">
																									<span>启动电机</span>
																								</div>
																							</div>

																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div id="c_portalResProduct_detail-15836818266447627"
																		class="c_portalResProduct_detail-01001">


																		<!-- 商品名称属性项 -->
																		<div class="e_box d_ProductDetail p_ProductDetail"
																			data-ename="产品详情框架">

																			<div class="product_info">
																				<!--左边结构-->
																				<div class="e_box p_imagesA c_portalResProduct_detail-15836818266447627-js"
																					data-ename="产品详情容器">
																					<div class="c_portalResProduct_detail-15836818266447627-imgjs"
																						data-innerdismoveable="">
																					</div>
																					<div class="e_box p_assist"
																						data-ename="互动信息组">
																						<!--浏览量-->
																						<div class="e_box p_ViewBox"
																							data-infoid="201"
																							data-ename="浏览数量信息区">
																							<div class="e_title p_AssistNameB"
																								data-ename="浏览数量引导标题">
																								<div class="font">
																									<i
																										class="iconfont"></i>浏览量:
																								</div>
																							</div>
																							<div class="e_title p_view"
																								data-ename="浏览数量">
																								<div class="font">
																									<i
																										class="iconfont"></i>
																									1000
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																				<!--右边结构-->
																				<div class="e_box p_ProInfo"
																					data-ename="主要信息区">
																					<div class="e_box p_content borderB_dividers"
																						data-ename="重要信息区">
																						<!--产品名称名称和标题开始-->
																						<div class="e_box d_ProName p_ProName"
																							data-ename="标题区">
																							<h1 class="e_title d_Title p_Title h2"
																								data-ename="名称">
																								<div class="font">
																									<i
																										class="iconfont"></i>{{productDetailsdata.name}}
																								</div>
																							</h1>
																						</div>
																						<!--产品名称名称和标题结束-->
																						<!--概要开始-->
																						<div class="e_box d_ProSummary p_ProSummary"
																							data-ename="概要描述区">
																							<article
																								class="e_article d_summary p_summary"
																								data-ename="概要描述">
																								<img :src="productDetailsdata.viewpic" style="width:390px;height:390px;">
																							</article>
																						</div>
																						<!--概要结束-->





																						<!--批发价市场价开始-->
																						<div class="e_box d_PriceBox p_PriceBox background_skuBg"
																							data-ename="价格信息区">
																							<div class="e_box d_SalePriceBox p_SalePriceBox"
																								data-ename="批发价格信息区">
																								<div class="e_title d_nameG p_nameG"
																									data-ename="批量价引导标题">
																									<div class="font">
																										零售价
																									</div>
																								</div>
																								<div class="e_title d_SalePrice p_SalePrice price"
																									data-ename="批发价">
																									<div class="font">
																										0.0
																									</div>
																								</div>
																								<div class="e_title d_unit p_unit"
																									data-ename="计量单位">
																									<div class="font">
																										Yuan
																									</div>
																								</div>
																							</div>
																							<div class="e_box d_MarketPriceBox p_MarketPriceBox"
																								data-ename="市场价格信息区">
																								<div class="e_title d_nameH p_nameH"
																									data-ename="市场价引导标题">
																									<div class="font">
																										市场价
																									</div>
																								</div>
																								<div class="e_title d_MarketPrice p_MarketPrice"
																									data-ename="市场价">
																									<div class="font">
																										0.0
																									</div>
																								</div>
																								<div class="e_title d_unitA p_unitA"
																									data-ename="计量单位">
																									<div class="font">
																										Yuan
																									</div>
																								</div>
																							</div>
																							<!--浏览量-->
																							<div class="e_box p_ViewBox"
																								data-infoid="201"
																								data-ename="浏览数量信息区">
																								<div class="e_title p_AssistNameB"
																									data-ename="浏览数量引导标题">
																									<div class="font">
																										<i
																											class="iconfont"></i>浏览量:
																									</div>
																								</div>
																								<div class="e_title p_view"
																									data-ename="浏览数量">
																									<div class="font">
																										<i
																											class="iconfont"></i>
																										1000
																									</div>
																								</div>
																							</div>
																						</div>
																						<!--批发价市场价结束-->
																						<div class="e_box d_NumBox p_NumBox"
																							data-ename="编号区">
																							<div class="e_title d_nameC p_nameC"
																								data-ename="编号引导标题">
																								<div class="font">
																									产品编号
																								</div>
																							</div>
																							<div class="e_title d_num p_num"
																								data-ename="编号">
																								<div class="font">
																								</div>
																							</div>
																						</div>
																						<!--产品编号名称和标题结束-->


																						<!--属性规格开始-->
																						<div
																							class="e_box d_BaseInfoBox p_BaseInfoBox background_form js_attrOne">
																						</div>

																						<!--属性规格结束-->






																						<!--选择颜色开始-->
																						<div class="e_box p_products">
																						</div>
																						<!--选择颜色结束-->
																						<!--数量增加删减开始-->
																						<div
																							class='e_box d_NumberBox p_NumberBox_'>
																							<div
																								class='e_title d_nameM p_nameM_'>
																								<div class="font">
																									数量
																								</div>
																							</div>
																							<div
																								class="e_btn d_minus p_minus border_default background_form">
																								<div class="font">
																									-
																								</div>
																							</div>
																							<input id="buyNum"
																								name="name" type="text"
																								class='e_input d_input p_input border_default'
																								value="1">
																							<div
																								class="e_btn d_plus p_plus border_default background_form">
																								<div class="font">
																									+
																								</div>
																							</div>
																							<div class="e_title d_nameO p_nameO"
																								data-ename="库存引导标题">
																								<div class="font">
																									库存:
																								</div>
																							</div>
																							<div class="e_title d_numA p_numA js_stockNum"
																								data-ename="库存数量">
																								<div class="font">
																									0
																								</div>
																							</div>
																						</div>
																					</div>

																					<!--按钮开始-->
																					<div class="e_box p_interaction"
																						data-ename="互动按钮区">
																						<!-- 询价和产品比较浮动框需要的数据 -->
																						<input type="hidden"
																							class="js_compareFloatData"
																							id="201" name="启动电机"
																							templateid="1"
																							image="/repository/image/83dba1dc-97ea-44b0-9ebd-ce5775f77f35.jpg">
																						<a href="contact21.html"
																							target="_self">
																							<button type="button"
																								class="btn btn-default p_inquiry "
																								data-ename="询价按钮">Contact
																								us</button>
																						</a>
																						<!--所属分类-->
																						<div class="e_box d_SortName p_SortName"
																							data-ename="分类信息区">
																							<div class="e_title d_nameD p_nameD"
																								data-ename="分类引导标题">
																								<div class="font">
																									所属分类
																								</div>
																							</div>
																							<a href="index8.html"
																								class="e_link d_linkA p_linkA"
																								target="_self">
																								<div class="e_title d_sort p_sort"
																									data-ename="分类名称">
																									<div class="font">
																										<i
																											class="iconfont"></i>Back
																										to
																										list
																									</div>
																								</div>
																							</a>
																						</div>
																						<!--所属分类结束-->
																						<div class="Prodownload hide">
																						</div>
																						<!-- 购物车 -->
																						<div class="e_box p_shopCartBox dom_mo"
																							data-ename="购物车容器">
																							<div class="e_box p_shopCart h1"
																								data-ename="购物车">
																								<div class="font">
																									<i
																										class="iconfont">&#xe693;</i>
																								</div>
																							</div>
																							<!-- 数量 -->
																							<div class="e_box p_shopCartNum background_shopCart color_horizon h6"
																								data-ename="购物车数量">
																								<div class="font">
																									1
																								</div>
																							</div>
																							<!-- 数量 结束 -->
																						</div>
																						<!-- 购物车 结束 -->
																					</div>
																					<!--按钮结束-->

																					<!--分享开始-->
																					<div
																						class="more_link bdsharebuttonbox">



																					</div>
																					<!--分享结束-->

																				</div>
																			</div>

																			<div class="e_box d_DescriptionBoxA p_DescriptionBoxA "
																				data-ename="详情信息区">
																				<!--切换标签标题开始-->
																				<div class="e_box d_TabTitleBox p_TabTitleBox"
																					data-ename="页签区">
																					<!-- 产品描述Tab -->
																					<div class='e_RelationBtn d_DecTab decTab active'
																						id="contentPage">
																						<div
																							class="d_DecTabA p_DecTabA_1">
																							<div class="font">
																								产品描述
																							</div>
																						</div>
																					</div>
																					<!-- 参数Tab -->
																					<div class='e_RelationBtn d_SpecTab d_DecTab decTab p_SpecTab_1'
																						id="paramPage">
																						<div
																							class="d_SpecTabA p_SpecTabA_1">
																							<div class="font">
																								参数
																							</div>
																						</div>
																					</div>
																				</div>
																				<!--切换标签标题开始-->
																				<!--产品详情页签容器开始-->
																				<div class="e_box d_DescriptionBigbox p_DescriptionBigbox borderT_default"
																					data-ename="页签内容区">
																					<!--产品描述-->
																					<div id="contentDiv"
																						class='e_box d_DescribeContent p_DescribeContent'
																						data-ename="描述内容区">
																						<!--产品介绍-->

																						<!--热门关键词-->
																						<!--热门关键词 end-->
																						<!--二维码-->
																						<div class="e_box p_QRCode "
																							data-ename="打开手机查看">
																							<div class="e_title p_SaveText "
																								data-ename="二维码引导信息">
																								<div class="font">
																									扫二维码用手机看
																								</div>
																							</div>
																							<div class="e_image js_qrcode"
																								data-ename="二维码">
																							</div>
																						</div>
																					</div>
																					<!--参数区-->
																					<div id="paramDiv"
																						class='e_box hide d_ProParametersBox p_ProParametersBox border_default'
																						data-ename="产品参数容器">
																						<!--质量参数-->
																						<div class="e_AbnormalPrompt p_PromptNoneData"
																							local="true">
																							<div class="promptbox">
																								<div
																									class="prompt_title">
																									<i
																										class="iconfont first"></i>
																									<div class="font">
																										未找到相应参数组，请于后台属性模板中添加
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																					<!--参数区-->
																					<!--自定义多页签-->
																				</div>
																				<!--上一页下一页-->
																				<div class="e_box d_nextBox p_nextBox"
																					data-ename="翻页容器">
																					<div class="e_box d_nextpage p_nextpage"
																						data-ename="上一篇容器">
																						<div class="e_title d_nextName p_nextName"
																							data-ename="引导标题">
																							<div class="font">
																								上一个
																							</div>
																						</div>
																						<a href="2021.html">
																							<div class="e_title d_MinorLink p_MinorLink"
																								data-ename="上一篇标题">
																								<div class="font">
																									闪光器
																								</div>
																							</div>
																						</a>
																					</div>
																					<div class="e_box d_nextpageA p_nextpageA"
																						data-ename="下一篇容器">
																						<div class="e_title d_nextNameA p_nextNameA"
																							data-ename="引导标题">
																							<div class="font">
																								下一个
																							</div>
																						</div>
																						<a href="200.html">
																							<div class="e_title d_MinorLink p_MinorLink"
																								data-ename="下一篇标题">
																								<div class="font">
																									链轮
																								</div>
																							</div>
																						</a>
																					</div>
																				</div>
																			</div>
																			<!-- 360弹层 -->
																			<div
																				class="e_box d_masker-wp p_masker-wp hide">
																				<div class="masker">
																				</div>
																				<div
																					class="e_box d_agreement p_agreement animate">
																					<div class="e_box d_top p_top">
																						<div
																							class="e_title d_Close p_Close">
																							<div class="font">
																								<i class="iconfont"></i>
																							</div>
																						</div>
																					</div>
																					<div class="e_title d_text p_text"
																						id="KeyShotVR">
																					</div>
																				</div>
																			</div>
																			<!-- 360弹层 结束 -->
																		</div>
																		<!--商品名称属性项 end-->
																		<div class="e_PopupWindowBox p_PopupWindow"
																			data-ename="弹窗容器">
																		</div>
																		<div class="e_box p_shade" data-ename="遮罩容器">
																		</div>
																		<input type="hidden"
																			value='{"specs":[],"skus":[{"id":924,"useViewType":false,"productId":201,"templateId":1,"code":"","name":"启动电机","stock":0,"price":0.00,"retailPrice":0.00,"weight":0.00,"status":"1","isDefault":"1","createDate":"2021-12-07 15:05:58","productSkuSpecs":[],"moq":1,"skuId":0,"chargedWeight":0.00}],"specConfs":[]}'
																			id="product-skuDetail">
																		<div class="videolist hide">
																			<input class="videoUrl" type="hidden"
																				value="" data-url="">
																			<input class="videoTitle" type="hidden"
																				value=''>
																			<input class="videoImgSrc" type="hidden"
																				value=''>
																		</div>
																	</div>
																	<div id="w_grid-1590373299234" class="w_grid-000">
																		<div class="w_grid signal">
																			<div class="e_box e_box-000 p_gridbox">
																				<div id="content_box-1590373299234-0"
																					class="e_box e_box-000 d_gridCell_0 p_gridCell">
																					<div id="w_common_text-1590373299243"
																						class="reset_style">
																						<div>
																							<p
																								style="text-align:center">
																								<span
																									style="font-family:arial,helvetica,sans-serif"><span
																										style="line-height:2"><span
																											style="color:#333333"><span
																												style="font-size:32px">&nbsp;FEEDBACK</span></span></span><br>
																									<span
																										style="line-height:2"><span
																											style="color:#cccccc"><span
																												style="font-size:20px">LISTEN
																												TO
																												THE
																												VOICE
																												OF
																												EVERY
																												CUSTOMER</span></span></span></span>
																							</p>
																						</div>
																					</div>
																					<div id="c_portalResMessage_form-15903732982723436"
																						class="c_portalResMessage_form-01001">
																						<div class="e_box p_inquiry"
																							data-ename="填写留言表单">
																							<div class="e_wbox p_ConWBox"
																								data-ename="弹窗内容容器">
																								<!--留言应用名称-->
																								<div class="e_box p_NameBox"
																									data-ename="留言应用名称容器">
																									<div class="e_title p_NameB"
																										data-ename="名称标题">
																										<span
																											class="i_messageCategoryName">留言应用名称：</span>
																									</div>
																									<div class="e_title p_NameInfoB"
																										data-ename="名称">
																										在线留言
																									</div>
																								</div>
																								<!--留言应用名称 结束-->
																								<!--信息描述-->
																								<div class="e_box p_DepictBoxB"
																									data-ename="描述信息容器">
																									<div class="e_title p_DepicNameB"
																										data-ename="描述引导标题">
																										<span
																											class="i_description">描述：</span>
																									</div>
																									<div class="e_title p_DepictInfoB"
																										data-ename="描述">
																									</div>
																								</div>
																								<!--信息描述 结束-->
																								<form id="form"
																									class="form-horizontal d_formItemBox p_productInfoForm"
																									novalidate="novalidate">
																									<input type="hidden"
																										id="categoryId"
																										class="required"
																										name="categoryId"
																										value="1">
																									<input type="hidden"
																										name="compId"
																										class="required"
																										value="portalResMessage_form-15903732982723436">
																									<input type="hidden"
																										name="referer"
																										class="required"
																										value="">
																									<!--联系信息-->
																									<div class="e_box p_ContactInfo"
																										data-ename="信息内容容器">
																										<!--多行文本-->
																										<div class="e_box form-group p_groupBox"
																											data-ename="多行文本容器">
																											<label
																												for="c_intentionIntro"
																												class="e_title col-sm-2 control-label d_formItemTitle h5 color_normal p_intentionIntroTitle">
																												<span
																													class="e_title d_formItemStar color_error p_intentionIntroStar">*</span>
																												内容：
																											</label>
																											<div class="e_text p_eqdescInput"
																												data-ename="多行文本输入框">
																												<!-- 使用disabled不可编辑不可点击，使用readonly不可编辑 -->
																												<textarea
																													class="InputText form-control"
																													id=""
																													name="item_50002"
																													maxlength="1000"
																													placeholder="输入留言内容"
																													data-required="true"
																													tit="内容"></textarea>
																											</div>
																										</div>
																										<!--多行文本-->
																										<!--单行文本-->
																										<div data-foreachitem=""
																											class="e_box form-group p_FullNameBox"
																											data-ename="单行文本信息区">
																											<label
																												for="c_intentionIntro"
																												class="e_title col-sm-2 control-label d_formItemTitle h5 color_normal p_intentionIntroTitle">
																												姓名：
																											</label>
																											<div class="e_input p_FullName"
																												data-ename="单行文本输入框">
																												<input
																													id=""
																													name="item_50007"
																													data-type="1"
																													data-subtype="1"
																													class="InputText form-control"
																													type="text"
																													data-required="false"
																													maxlength="32"
																													placeholder="输入你的姓名"
																													tit="姓名">
																												<div
																													class="e_unit p_UnitName">
																												</div>
																											</div>
																										</div>
																										<!--单行文本 结束-->
																										<!--数字-->
																										<!--单行文本-->
																										<div class="e_box form-group p_PhoneBox"
																											data-ename="手机信息区">
																											<label
																												for="c_intentionIntro"
																												class="e_title col-sm-2 control-label d_formItemTitle h5 color_normal p_intentionIntroTitle">
																												电话：
																											</label>
																											<div class="e_input p_phone"
																												data-ename="手机输入框">
																												<input
																													id=""
																													name="item_50009"
																													class="InputText form-control"
																													maxlength="20"
																													data-type="1"
																													data-subtype="3"
																													type="text"
																													data-required="false"
																													placeholder="输入你的手机号"
																													tit="电话">
																											</div>
																										</div>
																										<!--手机 结束-->
																										<!--电话-->
																										<!--单行文本-->
																										<div class="e_box form-group p_EmailBox"
																											data-ename="邮箱信息区">
																											<label
																												for="c_intentionIntro"
																												class="e_title col-sm-2 control-label d_formItemTitle h5 color_normal p_intentionIntroTitle">
																												邮箱：
																											</label>
																											<div class="e_input p_email"
																												data-ename="邮箱输入框">
																												<input
																													id=""
																													name="item_50012"
																													class="InputText form-control"
																													maxlength="64"
																													data-type="1"
																													data-subtype="5"
																													type="text"
																													data-required="false"
																													placeholder="输入你的邮箱"
																													tit="邮箱">
																											</div>
																										</div>
																										<!--邮箱 结束-->
																										<!--日期-->
																										<!--验证码-->
																										<div class="e_box form-group p_CaptchasBox"
																											data-ename="验证码">
																											<!-- 引导标题 -->
																											<label
																												for="c_intentionIntro"
																												class="e_title col-sm-2 control-label d_formItemTitle h5 color_normal p_intentionIntroTitle">
																												<span
																													class="e_title d_formItemStar color_error p_intentionIntroStar">*</span>
																												<span
																													class="i_captcha">
																													验证码：</span>
																											</label>
																											<!-- 引导标题 结束 -->
																											<div
																												class="asdfg">
																												<!--输入验证码-->
																												<div class="e_box p_EntryBox"
																													data-ename="输入验证码容器">
																													<!--验证码输入框-->
																													<div class="e_input p_EntryInput"
																														data-ename="验证码输入框">
																														<!-- 使用disabled不可编辑不可点击，使用readonly不可编辑 -->
																														<input
																															name="captchas"
																															class="InputText form-control"
																															type="input"
																															data-required="true"
																															value=""
																															placeholder="Please enter verification code"
																															maxlength="5"
																															tit="验证码"
																															data-error="验证码错误或已失效">
																													</div>
																													<!--验证码输入框 结束-->
																												</div>
																												<!--输入验证码 结束-->
																												<!--验证码图片-->
																												<div class="e_image p_image"
																													data-ename="验证码图片">
																													<img src=""
																														width="100%"
																														height="100%"
																														alt="验证码">
																												</div>
																												<!--验证码图片 结束-->
																											</div>
																										</div>
																										<!--验证码结束-->
																										<!--提交-->
																										<div
																											class="e_wbox p_CoupWBox">
																											<button
																												type="button"
																												class="btn btn-primary submitPC p_submit"
																												data-ename="提交按钮">提交</button>
																										</div>
																										<!--提交结束-->
																									</div>
																									<!--联系信息 结束-->
																								</form>
																							</div>
																							<!--弹窗内容容器 结束-->
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import '@/assets/css/page_impt_products_detail.css'
	import {productDetails} from '@/api'
	export default {
		data() {
			return {
				productDetailsdata:[]
			}
		},
		created() {
			// 组件创建时访问路由参数
			console.log(this.$route.params.id);
		},
		mounted() {
			// console.log('sss')
			
			this.productDetailsff(this.$route.params.id);
		},
		methods:{
			productDetailsff() {
				var parm = {
					id: this.$route.params.id
				}
				productDetails(parm).then(
					(res) => {
						if (res.status == 200) {
							this.productDetailsdata = res.data.data[0];
							console.log(res.data.data,'----listData----')
						}
					}
				).catch((req) => {
					this.$alert(req.message, ' ', {
						confirmButtonText: '确定',
						callback: action => {
							this.$message({
								type: 'info',
								message: `action: ${ action }`
							});
						}
					});
				});
			}
		}
	}
</script>

<style>
</style>