<template>
	<div id="w_grid-1564456455396" class="w_grid-000" data-tmpleditable="editable">
		<div class="w_grid signal">
			<div class="e_box e_box-000 p_gridbox">
				<div id="content_box-1564456455396-0" class="e_box e_box-000 d_gridCell_0 p_gridCell ND_empty"
					data-editablecontent="1564456455396">
					<div id="w_grid-1590140077700" class="w_grid-000">
						<div class="w_grid signal">
							<div class="e_box e_box-000 p_gridbox">
								<div id="content_box-1590140077700-0" class="e_box e_box-000 d_gridCell_0 p_gridCell">
									<div id="c_portalResPublic_codeContainer-1590140077825"
										class="c_portalResPublic_codeContainer-01001">
										<div class="e_box">
											<div class="in_about_b hxd">
												<img class="p_bgBox" src="@/assets/picture/bb3.jpg" alt="Green Stone ">
												<div class="text">
													<!-- 标题开始 -->

													<p class="bannerTitle en_font_bold">Looking
														forward to your joining next
													</p>
													<!-- 标题结束 -->

													<!-- banner概要开始 -->
													<article class="summry" style="display:none;">Looking forward to
														your joining next</article>
													<p class="banner_en en_font">Be Different,
														Be Success !</p>
													<!-- banner概要结束 -->
												</div>
												<div class="in_about_in "></div>
											</div>
										</div>
									</div>
									<div id="w_grid-1619080233703" class="w_grid-000">
										<div class="w_grid signal">
											<div class="e_box e_box-000 p_gridbox">
												<div id="content_box-1619080233703-0"
													class="e_box e_box-000 d_gridCell_0 p_gridCell">
													<div id="w_grid-1619080233715" class="w_grid-001">
														<div class="w_grid signal">
															<div class="e_box e_box-000 p_gridbox">
																<div id="content_box-1619080233715-0"
																	class="e_box e_box-000 d_gridCell_0 p_gridCell">
																	<div id="w_grid-1619080233726" class="w_grid-001">
																		<div class="w_grid ">
																			<div class="e_box e_box-000 p_gridbox">
																				<div id="content_box-1619080233726-0"
																					class="e_box e_box-000 d_gridCell_0 p_gridCell">
																					<div id="c_portalResIntro_category-16190802367826312"
																						class="c_portalResIntro_category-01001001">

																						<div class="NavList_a1">
																							<ul class="clearfix">
																								<li
																									class="en_font_bold">
																									<a href="contact2.html"
																										title="联系方式"
																										class="more">{{$t('user.chongqingcontent29')}}</a>
																								</li>
																								<li
																									class="en_font_bold">
																									<a href=""
																										title="在线留言"
																										class="more">{{$t('user.Feedback')}}</a>
																								</li>
																							</ul>
																						</div>

																					</div>
																				</div>
																				<div id="content_box-1619080233726-1"
																					class="e_box e_box-000 d_gridCell_1 p_gridCell">
																					<div id="c_portalResBreadcrumb_nav-16190802367890492"
																						class="c_portalResBreadcrumb_nav-01001">
																						<!--homePage  首页-->
																						<div class="e_box e_box-000 p_breadcrumbList"
																							data-ename="面包屑导航">

																							<div
																								class="e_breadcrumb e_breadcrumb-001 p_breadcrumbFirst">
																								<span class="weizhi"><i
																										class="iconfont"></i>Your
																									location：</span>
																								<a href="index.html">
																									<div class="font">
																										<span><span
																												class="i_homePage">{{$t('user.login')}}</span></span>
																									</div>
																								</a>
																							</div>
																							<!--compositePage 应用综合页对象-->
																							<!--navBreadcrumb 应用数据集合对象（list集合）-->

																							<!-- currentPage 当前页对象-->
																							<div
																								class="e_title e_GuideLabel-001 p_end">
																								<div class="font">
																									<span
																										class="i_separator">/</span>
																								</div>
																							</div>
																							<div
																								class="e_breadcrumb e_breadcrumb-000 p_breadcrumbCurrent">
																								<div class="font">
																									<span>Feedback</span>
																								</div>
																							</div>

																						</div>

																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div id="w_common_text-1590140077810" class="reset_style">
										<div>
											<h6 style="text-align:center"><span
													style="font-family:arial,helvetica,sans-serif"><span
														style="color:#222"><span style="font-size:32px">CONTACT
															US</span></span></span></h6>

											<h5 style="text-align:center"><span style="line-height:1.6"><span
														style="color:#cccccc"><span style="font-size:20px">CONTACT GREEN
															STONE</span></span></span></h5>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="w_grid-1590140077740" class="w_grid-000">
						<div class="w_grid signal">
							<div class="e_box e_box-000 p_gridbox">
								<div id="content_box-1590140077740-0" class="e_box e_box-000 d_gridCell_0 p_gridCell">
									<div id="w_grid-1590140077750" class="w_grid-000">
										<div class="w_grid signal">
											<div class="e_box e_box-000 p_gridbox">
												<div id="content_box-1590140077750-0"
													class="e_box e_box-000 d_gridCell_0 p_gridCell">
													<div id="w_grid-1590140077760" class="w_grid-001">
														<div class="w_grid signal">
															<div class="e_box e_box-000 p_gridbox">
																<div id="content_box-1590140077760-0"
																	class="e_box e_box-000 d_gridCell_0 p_gridCell">
																	<div id="w_grid-1590140077770" class="w_grid-001">
																		<div class="w_grid signal">
																			<div class="e_box e_box-000 p_gridbox">
																				<div id="content_box-1590140077770-0"
																					class="e_box e_box-000 d_gridCell_0 p_gridCell">
																					<div id="w_grid-1590140077780"
																						class="w_grid-000"
																						data-animation="once">
																						<div class="w_grid signal">
																							<div
																								class="e_box e_box-000 p_gridbox">
																								<div id="content_box-1590140077780-0"
																									class="e_box e_box-000 d_gridCell_0 p_gridCell">
																									<div id="c_portalResMessage_form-1590140077840"
																										class="c_portalResMessage_form-01001001">
																										<div class="e_box p_inquiry"
																											data-ename="填写留言表单">
																											<div class="e_wbox p_ConWBox"
																												data-ename="弹窗内容容器">
																												<!--留言应用名称-->
																												<div class="e_box p_NameBox"
																													data-ename="留言应用名称容器"
																													style="display:None;">
																													<div class="e_title p_NameB"
																														data-ename="名称标题">
																														<span
																															class="i_messageCategoryName">留言应用名称：</span>
																													</div>
																													<div class="e_title p_NameInfoB"
																														data-ename="名称">
																														在线留言
																													</div>
																												</div>
																												<!--留言应用名称 结束-->
																												<!--信息描述-->
																												<div class="e_box p_DepictBoxB"
																													data-ename="描述信息容器">
																													<div class="e_title p_DepicNameB"
																														data-ename="描述引导标题">
																														<span
																															class="i_description">描述：</span>
																													</div>
																													<div class="e_title p_DepictInfoB"
																														data-ename="描述">
																													</div>
																												</div>
																												<!--信息描述 结束-->
																												<form
																													id="form"
																													class="form-horizontal d_formItemBox p_productInfoForm"
																													novalidate="novalidate">
																													<input
																														type="hidden"
																														id="categoryId"
																														class="required"
																														name="categoryId"
																														value="1">
																													<input
																														type="hidden"
																														name="compId"
																														class="required"
																														value="portalResMessage_form-1590140077840">
																													<input
																														type="hidden"
																														name="referer"
																														class="required"
																														value="">
																													<!--联系信息-->
																													<div class="e_box p_ContactInfo"
																														data-ename="信息内容容器">

																														<!--多行文本-->
																														<div class="e_box form-group p_groupBox"
																															data-ename="多行文本容器">
																															<label
																																for="c_intentionIntro"
																																class="e_title col-sm-2 control-label d_formItemTitle h5 color_normal p_intentionIntroTitle">
																																<span
																																	class="e_title d_formItemStar color_error p_intentionIntroStar">*</span>
																																内容：
																															</label>
																															<div class="e_text p_eqdescInput"
																																data-ename="多行文本输入框">
																																<!-- 使用disabled不可编辑不可点击，使用readonly不可编辑 -->
																																<textarea
																																	class="InputText form-control"
																																	id=""
																																	name="item_50002"
																																	maxlength="1000"
																																	:placeholder="$t('user.chongqingcontent21')"
																																	data-required="true"
																																	tit="内容"></textarea>
																															</div>
																														</div>
																														<!--多行文本-->

																														<!--单行文本-->
																														<div data-foreachitem=""
																															class="e_box form-group p_FullNameBox"
																															data-ename="单行文本信息区">
																															<label
																																for="c_intentionIntro"
																																class="e_title col-sm-2 control-label d_formItemTitle h5 color_normal p_intentionIntroTitle">
																																姓名：
																															</label>
																															<div class="e_input p_FullName"
																																data-ename="单行文本输入框">
																																<input
																																	id=""
																																	name="item_50007"
																																	data-type="1"
																																	data-subtype="1"
																																	class="InputText form-control"
																																	type="text"
																																	data-required="false"
																																	maxlength="32"
																																	:placeholder="$t('user.chongqingcontent22')"
																																	tit="姓名">
																																<div
																																	class="e_unit p_UnitName">
																																</div>
																															</div>
																														</div>
																														<!--单行文本 结束-->
																														<!--数字-->


																														<!--单行文本-->
																														<!--手机-->
																														<div
																															class="e_box p_aaa">
																															<div class="e_box form-group p_PhoneBox"
																																data-ename="手机信息区">
																																<label
																																	for="c_intentionIntro"
																																	class="e_title col-sm-2 control-label d_formItemTitle h5 color_normal p_intentionIntroTitle">
																																	电话：
																																</label>
																																<div class="e_input p_phone"
																																	data-ename="手机输入框">
																																	<input
																																		id=""
																																		name="item_50009"
																																		class="InputText form-control"
																																		maxlength="20"
																																		data-type="1"
																																		data-subtype="3"
																																		type="text"
																																		data-required="false"
																																		:placeholder="$t('user.chongqingcontent23')"
																																		tit="电话">
																																</div>
																															</div>
																															<!--手机 结束-->
																														</div>
																														<!-- 手机容器 -->
																														<!--电话-->



																														<!--单行文本-->
																														<!--邮箱-->
																														<div
																															class="e_box p_aaa">
																															<div class="e_box form-group p_EmailBox"
																																data-ename="邮箱信息区">
																																<label
																																	for="c_intentionIntro"
																																	class="e_title col-sm-2 control-label d_formItemTitle h5 color_normal p_intentionIntroTitle">
																																	邮箱：
																																</label>
																																<div class="e_input p_email"
																																	data-ename="邮箱输入框">
																																	<input
																																		id=""
																																		name="item_50012"
																																		class="InputText form-control"
																																		maxlength="64"
																																		data-type="1"
																																		data-subtype="5"
																																		type="text"
																																		data-required="false"
																																		:placeholder="$t('user.chongqingcontent24')"
																																		tit="邮箱">
																																</div>
																															</div>
																															<!--邮箱 结束-->
																															<!-- 邮箱验证码 -->
																															<!-- 邮箱验证码 结束 -->
																														</div>
																														<!--邮箱容器 结束-->
																														<!--日期-->


																														<!--提交-->
																														<div
																															class="e_wbox p_CoupWBox">
																															<button
																																type="button"
																																class="btn btn-primary submitPC p_submit"
																																data-ename="提交按钮">{{$t('user.chongqingcontent25')}}</button>
																														</div>
																														<!--提交结束-->
																													</div>
																													<!--联系信息 结束-->
																												</form>
																											</div>
																											<!--弹窗内容容器 结束-->
																										</div>

																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import $ from 'jquery'
	import '@/assets/css/page_impt_Feedback.css'
	export default{
		data(){
			return{
				
			}
		},
		mounted(){
			
		}
	}
</script>

<style>
</style>