<template>
  <div class="about_content">
    <div class="about_header"></div>
    <div class="aboutFurui">
      <div class="about_one">
        <div class="blue_piece"></div>
        <p>{{ $t("user.enterFurui") }}</p>
      </div>
      <div class="about_two">{{ $t("user.chongqingcontent1") }}</div>
      <div class="about_three"></div>
      <div class="about_four">
        {{ $t("user.chongqingcontent14") }}
      </div>
    </div>
    <div class="about_three">
      <div class="aboutt_content">
        <div class="about_one">
          <div class="blue_piece"></div>
          <p>{{ $t("user.chongqingcontent5") }}</p>
        </div>
        <div class="title_box">{{ $t("user.chongqingcontent6") }}</div>
        <div class="heng_line"></div>
        <div class="device_box">
          <img src="../assets/furuiImg/aboutus/图片1.png" alt="" />
          <img src="../assets/furuiImg/aboutus/图片2.png" alt="" />
        </div>
      </div>
    </div>
    <div class="aboutus_four">
      <div class="aboutf_content">
        <div class="about_one">
          <div class="blue_piece"></div>
          <p>{{ $t("user.chongqingcontent7") }}</p>
        </div>
        <div class="title_box">{{ $t("user.chongqingcontent8") }}</div>
        <div class="heng_line"></div>
        <div><img src="../assets/furuiImg/aboutus/图片3.png" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .about_header {
    height: 270px !important;
  }
}
.about_content {
  width: 100%;
  .aboutus_four {
    width: 100%;
    background: url(../assets/furuiImg/aboutus/背景.png);
    background-size: cover;
    background-position: center;
    padding-bottom: 30px;
    .aboutf_content {
      width: 80vw;
      margin: 80px auto;
      > div > img {
        width: 100%;
      }
    }
  }
  .about_header {
    width: 100%;
    height: 520px;
    background: url(../assets/furuiImg/aboutus/banner.png);
    background-size: cover;
    background-position: center;
  }
  .aboutFurui {
    width: 80vw;
    margin: 80px auto;

    .about_two {
      font-size: 30px;
      font: black;
    }
    .about_three {
      width: 100%;
      height: 1px;
      margin: 30px auto;
      background-color: rgb(167, 165, 165);
    }
    .about_four {
      line-height: 3;
      text-indent: 4ch;
    }
  }
  .title_box {
    font-size: 32px;
  }
  .about_one {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    margin-bottom: 10px;
    .blue_piece {
      height: 11px;
      width: 5px;
      background-color: blue;
      margin-right: 10px;
    }
  }

  .heng_line {
    width: 100%;
    height: 1px;
    background-color: rgb(181, 181, 181);
    margin: 20px 0;
  }
  .about_three {
    width: 100%;
    background-color: #ebeef5;
    .aboutt_content {
      width: 80vw;
      margin: 0 auto;
      padding: 50px 0;

      .device_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > img {
          width: 38vw;
        }
      }
    }
  }
  .about_four {
    width: 100%;
    .aboutf_content {
      width: 80vw;
      margin: 0 auto;
    }
  }
}
</style>