<template>
  <div class="contact_content">
    <div class="contact_header"></div>
    <div class="con_aa">
      <div class="con_aa_box">
        <div class="cab_ch">{{ $t("user.chongqingcontent21") }}</div>
        <div class="cab_en">CONTACT INFORMATION</div>
        <div class="heng_line"></div>
        <div class="cab_descript">
          {{ $t("user.chongqingcontent22") }}
        </div>
      </div>
    </div>
    <div class="con_bb">
      <div class="cb_box">
        <div class="cbb_item">
          <div class="log"><i class="el-icon-mobile-phone"></i></div>
          <div class="enFont">Mobile</div>
          <div class="tel">13905942212</div>
        </div>
        <div class="su_line"></div>
        <div class="cbb_item">
          <div class="log"><i class="el-icon-message"></i></div>
          <div class="enFont">E-mail</div>
          <div class="tel">watta@fullyfj.com</div>
        </div>
        <div class="su_line"></div>
        <div class="cbb_item">
          <div class="log"><i class="el-icon-location-information"></i></div>
          <div class="enFont">Address</div>
          <div class="tel">{{ $t("user.chongqingcontent30") }}</div>
        </div>
      </div>
    </div>
    <div class="con_cc">
      <div class="cc_box">
        <div class="ccb_one">{{ $t("user.chongqingcontent31") }}</div>
        <div class="ccb_two">MAP LOCATION</div>
        <div class="heng_line"></div>
        <div class="ccb_map" id="map"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  data() {
    return {
      isMobile: this.$store.state.isMobile,
      current_position: [118.952867, 25.393084],
    };
  },
  mounted() {
    window._AMapSecurityConfig = {
      securityJsCode: "2ee182b8544661179f7064fdb2970672",
    };

    AMapLoader.load({
      key: "5dd74bc35f5d41e51160181f03284a98",
      version: "2.0",
      plugins: [
        "AMap.Geolocation",
        "AMap.PlaceSearch",
        "AMap.Scale",
        "AMap.OverView",
        "AMap.ToolBar",
        "AMap.MapType",
        "AMap.PolyEditor",
        "AMap.CircleEditor",
      ],
    })
      .then((AMap) => {
        const map = new AMap.Map("map", {
          viewMode: "2D", //是否为3D地图模式
          zoom: 16, //初始化地图级别
          center: this.current_position,
        });

        const marker = new AMap.Marker({
          position: this.current_position, // 标记点的位置
          title: "福瑞科技电子有限公司", // 鼠标悬停时显示的信息
          label: {
            // 标签内容
            content: "福瑞科技电子有限公司", // 标签文本
            offset: new AMap.Pixel(10, 0), // 标签相对于标记位置的偏移量
          },
          map: map,
        });

        marker.setMap(map);
        // map.setCenter(119, 25.42);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .contact_header {
    height: 270px !important;
  }
  .ccb_map {
    height: 30vh !important;
  }
  .su_line {
    height: 1px !important;
    width: 100% !important;
    margin: 20px auto !important;
  }
  .cb_box {
    flex-direction: column !important;
  }
}
.heng_line {
  width: 100%;
  height: 1px;
  background-color: rgb(181, 181, 181);
  margin: 20px 0;
}
.su_line {
  width: 1px;
  height: 10vw;
  background: rgb(181, 181, 181);
  margin: 0 20px;
}
.contact_content {
  width: 100%;
  .contact_header {
    width: 100%;
    height: 520px;
    background: url(../assets/furuiImg/contactus/banner.png);
    background-size: cover;
    background-position: center;
  }
  .con_aa {
    width: 100%;
    margin: 80px 0;
    .con_aa_box {
      width: 80vw;
      margin: 0 auto;
      .cab_ch {
        text-align: center;
        font-size: 32px;
      }
      .cab_en {
        font-size: 16px;
        text-align: center;
      }
      .cab_descript {
        text-align: center;
        line-height: 2;
        font-size: 18px;
      }
    }
  }
  .con_bb {
    width: 100%;
    .cb_box {
      width: 80vw;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      .cbb_item {
        text-align: center;
        .log {
          margin-bottom: 12px;
          > i {
            color: rgb(17, 119, 167);
            font-size: 28px;
          }
        }
        .enFont {
          font-size: 20px;
          margin-bottom: 2px;
        }
        .tel {
        }
      }
    }
  }
  .con_cc {
    width: 100%;
    margin: 80px 0;
    .cc_box {
      width: 80vw;
      margin: 0 auto;
      .ccb_one {
        text-align: center;
        font-size: 32px;
      }
      .ccb_two {
        text-align: center;
        font-size: 16px;
      }
      .ccb_map {
        height: 40vh;
        width: 100%;
        background: aqua;
        border-radius: 5px;
      }
    }
  }
}
</style>