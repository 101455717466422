<template>
	<div id="w_grid-1564456455396" class="w_grid-000" data-tmpleditable="editable">
		
		<!-- <div
			@click="changeLanguage"
			style="background-color: red;position: fixed;top: 0;z-index: 99999;width: 500px;height: 500px;">
			切换中英文的按钮 {{$t('user.login')}}</div> -->
		
		<div class="w_grid signal">
			<div class="e_box e_box-000 p_gridbox">
				<div id="content_box-1564456455396-0" class="e_box e_box-000 d_gridCell_0 p_gridCell ND_empty"
					data-editablecontent="1564456455396">
					<div id="w_grid-1564476740489" class="w_grid-000">
						<div class="w_grid signal">
							<div class="e_box e_box-000 p_gridbox">
								<div id="content_box-1564476740489-0" class="e_box e_box-000 d_gridCell_0 p_gridCell">
									<div id="c_portalResPublic_codeContainer-15773309496676983"
										class="c_portalResPublic_codeContainer-01001">
										<div class="e_box">
											<div class="in_about_b hxd">
												<img class="p_bgBox" src="@/assets/picture/bb3.jpg" alt="Green Stone ">
												<div class="text">
													<!-- 标题开始 -->

													<p class="bannerTitle en_font_bold">Looking
														forward to your joining next
													</p>
													<!-- 标题结束 -->

													<!-- banner概要开始 -->
													<article class="summry" style="display:none;">Looking forward to
														your joining next</article>
													<p class="banner_en en_font">Be Different,
														Be Success !</p>
													<!-- banner概要结束 -->
												</div>
												<div class="in_about_in "></div>
											</div>
										</div>
									</div>
									<div id="w_grid-1564476740499" class="w_grid-000">
										<div class="w_grid signal">
											<div class="e_box e_box-000 p_gridbox">
												<div id="content_box-1564476740499-0"
													class="e_box e_box-000 d_gridCell_0 p_gridCell">
													<div id="w_grid-1564476740509" class="w_grid-001">
														<div class="w_grid signal">
															<div class="e_box e_box-000 p_gridbox">
																<div id="content_box-1564476740509-0"
																	class="e_box e_box-000 d_gridCell_0 p_gridCell">
																	<div id="w_grid-1564476740519" class="w_grid-001">
																		<div class="w_grid ">
																			<div class="e_box e_box-000 p_gridbox">
																				<div id="content_box-1564476740519-0"
																					class="e_box e_box-000 d_gridCell_0 p_gridCell">
																					<div id="c_portalResNews_category-15644771256702908"
																						class="c_portalResNews_category-01001">

																						<div class="NavList_a1">
																							<ul class="clearfix">
																								
																								<li v-for="(item, index) in typeItem"
																									:key="index"
																									class="en_font_bold"
																									style="cursor: pointer;">
																									<a @click="byArticleTypeListItem(item.name)"
																										:key="item.id"
																										class="more">
																										{{item.name}}</a>
																								</li>
																							</ul>
																						</div>
																					</div>
																				</div>
																				<div id="content_box-1564476740519-1"
																					class="e_box e_box-000 d_gridCell_1 p_gridCell">
																					<div id="c_portalResBreadcrumb_nav-1564476740559"
																						class="c_portalResBreadcrumb_nav-01001"
																						data-vhide1024="true"
																						data-vhide768="true">
																						<!--homePage  首页-->
																						<div class="e_box e_box-000 p_breadcrumbList"
																							data-ename="面包屑导航">

																							<div
																								class="e_breadcrumb e_breadcrumb-001 p_breadcrumbFirst">
																								<span class="weizhi"><i
																										class="iconfont"></i>Your
																									location：</span>
																								<a href="/">
																									<div class="font">
																										<span><span
																												class="i_homePage">{{$t('user.login')}}</span></span>
																									</div>
																								</a>
																							</div>
																							<!--compositePage 应用综合页对象-->
																							<!--navBreadcrumb 应用数据集合对象（list集合）-->

																							<!-- currentPage 当前页对象-->
																							<div
																								class="e_title e_GuideLabel-001 p_end">
																								<div class="font">
																									<span
																										class="i_separator">/</span>
																								</div>
																							</div>
																							<div
																								class="e_breadcrumb e_breadcrumb-000 p_breadcrumbCurrent">
																								<div class="font">
																									<span>{{value}}</span>
																								</div>
																							</div>

																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="w_grid-1564476740529" class="w_grid-000">
						<div class="w_grid signal">
							<div class="e_box e_box-000 p_gridbox">
								<div id="content_box-1564476740529-0" class="e_box e_box-000 d_gridCell_0 p_gridCell">
									<div id="w_grid-1564476740539" class="w_grid-001">
										<div class="w_grid signal">
											<div class="e_box e_box-000 p_gridbox">
												<div id="content_box-1564476740539-0"
													class="e_box e_box-000 d_gridCell_0 p_gridCell">
													<div id="c_portalResNews_list-15773315127448500"
														class="c_portalResNews_list-01001" data-animation="once">

														<div>


															<!-- 咨询整体框架开始 -->
															<div class="newListBox">
																<!-- 咨询整体开始 -->
																<div class="p_news">
																	<!-- 单个列表开始 -->
																	<div class="newList"
																		v-for="(item,index) in dataList"
																		:key="index" style="cursor: pointer;">

																		<!-- 遮罩开始 -->
																		<a @click="$router.push({ name: 'newdetail',  params: { id: item.id } });"
																			class="xq">
																		</a>
																		<!-- 遮罩结束 -->
																		<div class="newListCon">

																			<!-- 时间 -->
																			<div class="newTimeBox">
																				<div class="year">
																					{{item.releaseTime | extract-year}}
																				</div>
																				<div class="mon">
																					{{item.releaseTime | format-date}}
																				</div>
																				<div class="dotBlack">
																					<img src="@/assets/picture/rBQBHVzbyl2ELI7DAAAAAJ-QIag888.png"
																						alt="小黑点" class="dott">
																				</div>

																			</div>

																			<!-- 内容开始 -->
																			<div class="content">
																				<!-- 标题开始 -->
																				<h3 class="newTitle">
																					<a class="newTitleLink">
																						{{item.name}}</a>
																				</h3>
																				<!-- 标题结束 -->

																				<!-- 描述开始 -->
																				<article class="newMs">
																					{{item.content}}
																				</article>
																				<!-- 描述结束 -->
																				<!-- 查看详情开始 -->
																				<a class="newDetailLink">
																					<img src="@/assets/picture/rBQBHVzbzLOED9eBAAAAAEigkY4424.png"
																						alt="查看详情" class="jttt">
																				</a>
																				<!-- 查看详情结束 -->
																			</div>

																			<!-- 内容结束 -->
																		</div>

																	</div>

																	<!-- 单个列表结束 -->
																</div>
																<!-- 咨询整体结束 -->
																<!-- 分页开始 -->
																<div class="e_box e_OccupyBox-001 p_OccupyBox">


																	<div class="e_pagebox e_pagebox-000 p_page">
																		<div class="pre">
																			<div class="font">
																				<i class="iconfont"></i><span
																					class="i_pre">上一页</span>
																			</div>
																		</div>
																		<div class="pageNum active"
																			v-for="(item, index) in dataList.totalPages"
																			:key="index"
																			@click="byArticleTypeListItem(value, index+1)">
																			{{index+1}}
																		</div>

																		<!-- <div class="pageNum">2
																		</div>
																		<div class="p_PageInterval">
																			<span class="i_omission">...</span>
																		</div>

																		<div class="pageNum">5
																		</div> -->
																		<div class="next">
																			<div class="font">
																				<i class="iconfont"></i><span
																					class="i_next">下一页</span>
																			</div>
																		</div>
																	</div>


																</div>
																<!-- 分页结束 -->
															</div>
															<!-- 咨询整体框架结束 -->

															<input type="hidden" class="requsetParam" value="&cid=1">
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import $ from 'jquery';
	import '@/assets/css/page_impt_news.css'
	import {
		articleType,
		byArticleTypeList
	} from '@/api'
	export default {
		data() {
			return {
				typeItem: [],
				dataList: [],
				value: '公司新闻',
				page: 1
			}
		},
		mounted() {
			// this.getTypeItem();
			// this.byArticleTypeListItem();
		},
		methods: {
			changeLanguage() {
				console.log("中文英文切换")
				this.$i18n.locale == 'zh' ? this.$i18n.locale = 'en' : this.$i18n.locale = 'zh' //设置中英文模式
				localStorage.setItem('languageSet', this.$i18n.locale) //将用户设置存储到localStorage以便用户下次打开时使用此设置
			},
			getTypeItem() {

				articleType().then(
					(res) => {
						console.log(res, "res")
						if (res.status == 200) {
							this.typeItem = res.data.data;
						}
					}
				).catch((req) => {
					this.$alert(req.message, ' ', {
						confirmButtonText: '确定',
						callback: action => {
							this.$message({
								type: 'info',
								message: `action: ${ action }`
							});
						}
					});
				});
			},
			byArticleTypeListItem(val, page) {
				if (page) this.page = page;
				if (val) this.value = val;
				var parm = {
					articleType: this.value,
					page: this.page,
					size: 100
				}
				byArticleTypeList(parm).then(
					(res) => {
						console.log(res, '----listData----')
						if (res.status == 200) {
							this.dataList = res.data.data;
						}
					}
				).catch((req) => {
					this.$alert(req.message, ' ', {
						confirmButtonText: '确定',
						callback: action => {
							this.$message({
								type: 'info',
								message: `action: ${ action }`
							});
						}
					});
				});
			}
		}
	}
</script>

<style>
</style>