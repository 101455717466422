import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/global.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/ReactQuilll.less'
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
// jquery 
import $ from 'jquery';


// 飞阳旧站点样式引入
import './assets/css/animate.css,iconfont.css,bootstrap.min.css,response.min.css';
import './assets/css/site_impt_r.css';
import './assets/css/ftmpl_impt_04c64aa1-4cc1-4297-a28f-ac1a2f501a01.css';
import './assets/css/page_impt_xly.css';
import './assets/css/public_layout.css';



Vue.config.productionTip = false
Vue.use(ElementUI);

// 时间过滤器
Vue.filter('extract-year', function(value) {
	if (!value) return '';
	// 假设日期格式是 YYYY-MM-DD
	return value.substring(0, 4);
});
Vue.filter('format-date', function(value) {
	if (!value) return '';
	// 假设日期格式是 YYYY-MM-DD
	const parts = value.split('-');
	// 检查是否有年月日三个部分
	if (parts.length === 3) {
		return `${parts[1]}-${parts[2]}`;
	}
	return value; // 如果不符合预期格式，原样返回
});


//中英文切换
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
	locale: localStorage.getItem('languageSet') || 'zh', //从localStorage里获取用户中英文选择，没有则默认中文
	messages: {
		'zh': require('./components/language/zh'),
		'en': require('./components/language/en')
	}
})



// 返回页面顶部
$(function() {
	$('.to_back').click(function() {
		$("html,body").animate({
			scrollTop: 0
		}, 500);
	});
})

new Vue({
	router,
	store,
	i18n,
	render: function(h) {
		return h(App)
	}
}).$mount('#app')